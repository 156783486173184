import React, { useState, useEffect } from "react";

import { Datepicker } from "flowbite-react";

import link from "../../../utils/CurrentLink.ts";

interface ServiceFormProps {
  type: string;
}

export default function ServiceForm({ type }: ServiceFormProps) {
  const formatType = (type: string) => {
    switch (type) {
      case "surat-cuti":
        return "Surat Cuti";
      case "surat-rekomendasi-beasiswa":
        return "Surat Rekomendasi Beasiswa";
      case "surat-keterangan-tugas-akhir":
        return "Surat Keterangan Sedang Menyelesaikan Tugas Akhir";
      case "surat-dispensasi":
        return "Surat Dispensasi";
      case "surat-aktif-kuliah":
        return "Surat Aktif Kuliah";
      case "peminjaman-ruangan":
        return "Peminjaman Ruangan";
      case "peminjaman-alat-dan-bahan":
        return "Peminjaman Alat dan Bahan Lab";
      case "pembuatan-skl-sertifikat-s1-farmasi-umum":
        return "Pembuatan SKL dan Sertifikat S1 Farmasi Umum";
      case "pembuatan-skl-sertifikat-s1-farmasi-klinik":
        return "Pembuatan SKL dan Sertifikat S1 Farmasi Klinik";
      case "pengajuan-transkrip-sementara":
        return "Pengajuan Transkrip Sementara";
      case "peminjaman-ruangan-teater":
        return "Peminjaman Ruangan Teater";
      default:
        return type;
    }
  };

  const [formData, setFormData] = useState({
    type: formatType(type),
    name: "",
    nim: "",
    birth: "",
    address: "",
    study_program: "",
    degree: "",
    registered_since: "",
    current_semester: 1,
    scholarship_from: "",
    start_date: "",
    end_date: "",
    reason: "",
    room: "",
    tool: "",
  });

  useEffect(() => {
    setFormData((prev) => ({ ...prev, type: formatType(type) }));
  }, [type]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (
    name: "start_date" | "end_date",
    date: Date | null
  ) => {
    if (date) {
      const formattedDate = date.toISOString().split("T")[0]; // Format YYYY-MM-DD
      setFormData((prev) => ({ ...prev, [name]: formattedDate }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${link}/letter `, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Pengajuan berhasil dikirim!");
      } else {
        alert("Gagal mengirim pengajuan.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Terjadi kesalahan, silakan coba lagi.");
    }
  };

  return (
    <section className="mb-24">
      <div className="auto-container ">
        <div className="flex justify-center mx-auto">
          <div className="max-w-3xl">
            <h3 className="checkout__title text-center">Formulir Pengajuan</h3>
            <div className="comment-one__form">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <p className="text-bold mb-2">Nama Lengkap : </p>
                    <div className="comment-form__input-box">
                      <input
                        type="text"
                        placeholder="Masukkan Nama Lengkap"
                        name="name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <p className="text-bold mb-2">
                      NIM (Nomor Induk Mahasiswa) :{" "}
                    </p>
                    <div className="comment-form__input-box">
                      <input
                        type="text"
                        placeholder="Masukkan NIM Mahasiswa"
                        name="nim"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <p className="text-bold mb-2">Tempat Tanggal Lahir : </p>
                    <div className="comment-form__input-box">
                      <input
                        type="text"
                        placeholder="cth: Samarinda, 01 Januari 2002"
                        name="birth"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <p className="text-bold mb-2">Alamat : </p>
                    <div className="comment-form__input-box">
                      <input
                        type="text"
                        placeholder="Masukkan Alamat"
                        name="address"
                        onChange={handleChange}
                        className="w-full"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <p className="text-bold mb-2">Semester saat ini : </p>
                    <div className="comment-form__input-box">
                      <select
                        name="current_semester"
                        onChange={handleChange}
                        className="custom-select-box"
                      >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <p className="text-bold mb-2">Program Studi : </p>
                    <div className="comment-form__input-box">
                      <select
                        name="study_program"
                        className="custom-select-box"
                        onChange={handleChange}
                      >
                        {[
                          "Sarjana Farmasi",
                          "Sarjana Farmasi Klinis",
                          "Diploma III Farmasi",
                          "Profesi Apoteker",
                        ].map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <p className="text-bold mb-2">Strata : </p>
                    <div className="comment-form__input-box">
                      <select
                        name="degree"
                        onChange={handleChange}
                        className="custom-select-box"
                      >
                        {["D3", "S1", "Apt"].map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <p className="text-bold mb-2">Tahun angkatan : </p>
                    <div className="comment-form__input-box">
                      <select
                        name="registered_since"
                        onChange={handleChange}
                        className="custom-select-box"
                      >
                        {[
                          "2017",
                          "2018",
                          "2019",
                          "2020",
                          "2021",
                          "2022",
                          "2023",
                          "2025",
                        ].map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {type === "surat-rekomendasi-beasiswa" && (
                    <div className="col-md-12">
                      <p className="text-bold mb-2">Nama Mitra Beasiswa : </p>
                      <div className="comment-form__input-box">
                        <input
                          type="text"
                          name="scholarship_from"
                          onChange={handleChange}
                          placeholder="Masukkan nama program atau mitra beasiswa"
                        />
                      </div>
                    </div>
                  )}

                  {(type === "surat-dispensasi" ||
                    type === "peminjaman-ruangan") && (
                      <div className="col-md-12">
                        <p className="text-bold mb-2">Alasan / Keperluan : </p>
                        <div className="comment-form__input-box">
                          <input
                            type="text"
                            name="reason"
                            onChange={handleChange}
                            placeholder="Masukkan alasan atau keperluan"
                          />
                        </div>
                      </div>
                    )}

                  {(type === "surat-dispensasi" ||
                    type === "peminjaman-ruangan" ||
                    type === "peminjaman-alat-dan-bahan") && (
                      <div className="flex flex-col lg:flex-row gap-3 mb-12">
                        <div className="flex-1">
                          <p className="text-bold mb-2">Tanggal Awal:</p>
                          <Datepicker
                            labelTodayButton="Hari ini"
                            labelClearButton="Clear"
                            selected={
                              formData.start_date
                                ? new Date(formData.start_date)
                                : undefined
                            }
                            language="id"
                            onChange={(date: Date) =>
                              handleDateChange("start_date", date)
                            }
                          />
                        </div>
                        <div className="flex-1">
                          <p className="text-bold mb-2">Tanggal Selesai:</p>
                          <Datepicker
                            labelTodayButton="Hari ini"
                            labelClearButton="Clear"
                            selected={
                              formData.end_date
                                ? new Date(formData.end_date)
                                : undefined
                            }
                            language="id"
                            onChange={(date: Date) =>
                              handleDateChange("end_date", date)
                            }
                          />
                        </div>
                      </div>
                    )}

                  {type === "peminjaman-alat-dan-bahan" && (
                    <div className="col-md-12">
                      <p className="text-bold mb-2">Nama Alat / Bahan : </p>
                      <div className="comment-form__input-box">
                        <input
                          type="text"
                          name="tool"
                          onChange={handleChange}
                          placeholder="Masukkan nama alat atau bahan yang dipinjam"
                        />
                      </div>
                    </div>
                  )}

                  {type === "peminjaman-ruangan" && (
                    <div className="col-md-12">
                      <p className="text-bold mb-2">Ruangan : </p>
                      <div className="comment-form__input-box">
                        <input
                          type="text"
                          name="room"
                          handleChange={handleChange}
                          placeholder="Masukkan ruangan yang dipinjam"
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-12 flex justify-center">
                    <button class="thm-btn" type="submit">
                      Kirim Pengajuan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
