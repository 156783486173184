import React, { useState, useEffect } from 'react';
import MainLayout from '../../layout/MainLayout.tsx';
import PageHeader from '../../components/PageHeader.tsx';

export default function NecessarilyInfo() {
 

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Informasi Diumumkan Serta Merta"
          breadcrumb={[
            { label: 'Beranda', link: '/' },
            { label: 'Informasi Diumumkan Serta Merta', link: '/ppid/informasi-diumumkan-serta-merta' },
          ]}
        ></PageHeader>

  
      </MainLayout>
    </>
  );
}
