import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);
  const [isOpenEducation, setIsOpenEducation] = useState(false);
  const [isOpenLab, setIsOpenLab] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Deteksi scroll
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggle = () => {
    setIsExpanded(true);
    document.body.classList.add("locked");
  };

  const handleClose = () => {
    setIsExpanded(false);
    document.body.classList.remove("locked");
  };

  const handleToggleList = (menu: string) => {
    setExpandedMenu(expandedMenu === menu ? null : menu); // Jika sudah aktif, tutup. Jika tidak, buka.
  };

  return (
    <>
      <header className="main-header main-header--one  clearfix">
        <div className="main-header--one__wrapper  ">
          <div className="main-header--one__top clearfix">
            <div className="auto-container ">
              <div className="main-header--one__top-left">
                <div className="text">
                  <p>
                    Selamat datang di Fakultas Farmasi Universitas Mulawarman
                  </p>
                </div>
                <div className="social-link clearfix">
                  <ul>
                    <li>
                      <Link to="https://x.com/farmasiunmul">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://web.facebook.com/profile.php?id=179449648732948&paipv=0&eav=AfYw3nG7qL7y4sar7XjObCuYkZQlsCrZT7xlfr-Fonbw4m2v9Heb1rTWZ0y_ptmX8-A&_rdc=1&_rdr">
                        <i className="fab fa-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.youtube.com/@farmatroptv967">
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/farmasi_unmul/">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="main-header--one__top-right clearfix">
                <ul>
                  <li>
                    <div className="icon">
                      <i className="fa fa-envelope"></i>
                    </div>
                    <div className="text">
                      <p>
                        <Link to="mailto:fa_mul@farmasi.unmul.ac.id">
                          fa_mul@farmasi.unmul.ac.id
                        </Link>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fa fa-clock"></i>
                    </div>
                    <div className="text">
                      <p>
                        Senin - Jum'at 8:00 - 16:00 WITA, Akhir Pekan - Tutup
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="main-header--one__bottom clearfix max-w-[1300px] mx-auto">
            <div className="auto-container flex justify-between items-baseline lg:block">
              <img
                className="dark-logo xl:hidden"
                style={{ maxWidth: "100px" }}
                src="/assets/images/resources/unmul-blu.png"
                alt=""
              />

              <div className="main-header--one__bottom-inner">
                <div className="main-header--one__bottom-right clearfix">
                  <div className="logo">
                    <Link to="/">
                      <img
                        className="dark-logo"
                        style={{ maxWidth: "100px" }}
                        src="/assets/images/resources/unmul-blu.png"
                        alt=""
                      />
                      <img
                        className="light-logo"
                        style={{ maxWidth: "100px" }}
                        src="/assets/images/resources/unmul-blu.png"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>

                <nav className="main-menu main-menu--1">
                  <div className="main-menu__inner">
                    <Link
                      to="#"
                      onClick={handleToggle}
                      className="mobile-nav__toggler"
                    >
                      <i className="fa fa-bars"></i>
                    </Link>

                    <div className="stricky-one-logo">
                      <div className="logo">
                        <Link to="/">
                          <img
                            className="dark-logo"
                            style={{ maxWidth: "100px" }}
                            src="/assets/images/resources/unmul-blu.png"
                            alt=""
                          />
                          <img
                            className="light-logo"
                            src="/assets/images/resources/logo-2.png"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>

                    <div className="main-header--one__bottom-left">
                      <ul className="main-menu__list">
                        {/* <li className="dropdown megamenu">
                                                <Link to="/">Beranda</Link>
                                            
                                            </li> */}

                        {/* <li className="dropdown">
                                                <Link to="#">Profil <i class="fa fa-angle-down"></i></Link>

                                                
                                                <ul className="w-[50vw] lg:w-[40vw]  flex flex-row justify-start p-4">

                                                    <li className=" flex-1">
                                                        <p className="font-bold text-green-800">Tentang Kami</p>
                                                        <div className=" inline-block">
                                                                <li><Link to="/sejarah">Sejarah</Link></li>
                                                                <li><Link to="/visi-misi">Visi dan Misi</Link></li>
                                                                <li><Link to="/pimpinan-fakultas">Pimpinan Fakultas</Link></li>
                                                                <li><Link to="/struktur-organisasi">Struktur Organisasi</Link></li>
                                                                <li><Link to="/dosen">Dosen</Link>
                                                                </li>
                                                                <li><Link to="/tendik">Tenaga Kependidikan</Link></li>
                                                                <li><Link to="/zona-integritas">Zona Integritas</Link></li>


                                                        </div>

                                                    </li>


                                                    <li className=" flex-1">
                                                        <p className="font-bold text-green-800">Program Studi</p>
                                                        <div className="inline-block">
                                                            <li><Link to="/prodi/diploma">Diploma</Link></li>
                                                            <li><Link to="/prodi/s1-farmasi">S1 Farmasi</Link></li>
                                                            <li><Link to="/prodi/s1-farmasi-klinis">S1 Farmasi Klinis</Link></li>
                                                            <li><Link to="/prodi/profesi-apoteker">Profesi Apoteker</Link></li>

                                                        </div>

                                                    </li>

                                                    <li className=" flex-">
                                                        <p className="font-bold text-green-800">Laboratorium</p>
                                                        <div className="inline-block">
                                                        <li><Link to="/content/laboratorium-farmakognosi--kimia-medisinal">Lab Farmakognosi - Kimia Medisinal</Link></li>
                                                        <li><Link to="/content/laboratorium-farmasetika">Lab Farmasetika</Link></li>
                                                        <li><Link to="/content/laboratorium-farmakologi">Lab Farmakologi</Link></li>
                                                        <li><Link to="/content/laboratorium-farmasi-klinik-dan-komunitas">Lab Farmasi Klinik dan Komunitas</Link></li>
                                                        <li><Link to="/content/laboratorium-umum-pendidikan-penelitian-kefarmasian">Lab Umum Pendidikan dan Penelitian Kefarmasian</Link></li>
                                                        <li><Link to="/content/laboratorium-praktek-kerja-kefarmasian">Lab Praktek Kerja Kefarmasian</Link></li>

                                                        </div>

                                                    </li>


                                                    
                                                
                                            

                                                </ul>
                                            </li> */}

                        <li className="dropdown">
                          <Link to="#">
                            Profil <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li>
                              <Link to="/sejarah">Sejarah</Link>
                            </li>
                            <li>
                              <Link to="/visi-misi">Visi dan Misi</Link>
                            </li>
                            <li>
                              <Link to="/pimpinan-fakultas">
                                Pimpinan Fakultas
                              </Link>
                            </li>
                            <li>
                              <Link to="/struktur-organisasi">
                                Struktur Organisasi
                              </Link>
                            </li>
                            <li>
                              <Link to="/dosen">Dosen</Link>
                            </li>
                            <li>
                              <Link to="/tendik">Tenaga Kependidikan</Link>
                            </li>
                            <li>
                              <Link to="/zona-integritas">Zona Integritas</Link>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <Link to="#">
                            Akademik <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li>
                              <Link to="/penelitian">Penelitian</Link>
                            </li>
                            <li>
                              <Link to="/pengabdian">Pengabdian</Link>
                            </li>

                            <li>
                              <Link to="/buku-dan-riset">Buku dan Riset</Link>
                            </li>

                            <li>
                              <Link to="/kalender-akademik">
                                Kalender Akademik
                              </Link>
                            </li>
                            <li>
                              <Link to="/biaya-pendidikan">
                                Biaya Pendidikan
                              </Link>
                            </li>

                            <li>
                              <Link to="/">KEPK</Link>
                              <ul>
                                <li>
                                  <Link to="https://sim-epk.farmasi.unmul.ac.id/">
                                    Website KEPK
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/kepk">Informasi</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="https://pmb.unmul.ac.id/">
                                Pendaftaran Mahasiswa Baru
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <Link to="#">
                            Program Studi <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li>
                              <Link to="/prodi/diploma">Diploma</Link>
                            </li>
                            <li>
                              <Link to="/prodi/s1-farmasi">S1 Farmasi</Link>
                            </li>
                            <li>
                              <Link to="/prodi/s1-farmasi-klinis">
                                S1 Farmasi Klinis
                              </Link>
                            </li>
                            <li>
                              <Link to="/prodi/profesi-apoteker">
                                Profesi Apoteker
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <Link to="#">
                            Penjaminan Mutu <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li>
                              <Link to="/tentang-penjamin-mutu-fakultas">
                                Tentang Penjamin Mutu Fakultas
                              </Link>
                            </li>

                            <li>
                              <Link to="https://drive.google.com/drive/folders/1WwtrqmX9fxXSGIagSXR2XAIVkiA8o834">
                                SPMI
                              </Link>
                            </li>

                            <li>
                              <Link to="https://drive.google.com/drive/folders/1oDLvPQ-iQBQNraK8nbNioZi3TVABGI5n">
                                RTM (Rapat Tinjauan Manajemen)
                              </Link>
                            </li>

                            <li>
                              <Link to="https://drive.google.com/drive/folders/1rHOj3_m-IvzNBeLUiF8C07mAlyuYZQ_o?usp=sharing">
                                Laporan Audit Mutu Internal
                              </Link>
                            </li>

                            <li>
                              <Link to="/kriteria-data-akreditasi">
                                Kriteria Data Akreditasi
                              </Link>
                            </li>

                            <li>
                              <Link to="/survey-kepuasan-civitas">
                                Survey Kepuasan Civitas Akademika
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <Link to="#">
                            Fasilitas <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li>
                              <Link to="/content/ruang-teater">
                                Ruang Teater
                              </Link>
                            </li>
                            <li>
                              <Link to="/content/perpustakaan">
                                Perpustakaan
                              </Link>
                            </li>
                            <li>
                              <Link to="/content/smart-classroom">
                                Smart Classroom
                              </Link>
                            </li>
                            <li>
                              <Link to="/content/ruang-kuliah-farmasi">
                                Ruang Kuliah Farmasi
                              </Link>
                            </li>
                            <li>
                              <Link to="/content/mushola">Mushola</Link>
                            </li>
                            <li
                              className="dropdown ml-0 pl-0"
                              style={{ marginLeft: 0 }}
                            >
                              <Link to="#">Laboratorium </Link>
                              <ul>
                                <li>
                                  <Link to="/content/laboratorium-farmakognosi--kimia-medisinal">
                                    Lab Farmakognosi - Kimia Medisinal
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/content/laboratorium-farmasetika">
                                    Lab Farmasetika
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/content/laboratorium-farmakologi">
                                    Lab Farmakologi
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/content/laboratorium-farmasi-klinik-dan-komunitas">
                                    Lab Farmasi Klinik dan Komunitas
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/content/laboratorium-umum-pendidikan-penelitian-kefarmasian">
                                    Lab Umum Pendidikan dan Penelitian
                                    Kefarmasian
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/content/laboratorium-praktek-kerja-kefarmasian">
                                    Lab Praktek Kerja Kefarmasian
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <Link to="#">
                            Informasi <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li>
                              <Link to="/berita">Berita</Link>
                            </li>
                            <li>
                              <Link to="/pengumuman">Pengumuman</Link>
                            </li>
                            <li>
                              <Link to="/galeri">Galeri</Link>
                            </li>
                            <li>
                              <Link to="/acara">Acara & Agenda</Link>
                            </li>

                            <li>
                              <Link to="/akreditasi">Akreditas</Link>
                            </li>

                            <li>
                              <Link to="/perpustakaan">Perpustakaan</Link>
                            </li>
                            <li>
                              <Link to="/informasi-regulasi">Regulasi</Link>
                            </li>
                            <li>
                              <Link to="/content/beasiswa">Beasiswa</Link>
                            </li>

                            <li>
                              <Link to="/mitra-kerjasama">Mitra Kerjasama</Link>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <Link to="#">
                            Kemahasiswaan <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li>
                              <Link to="/content/bimbingan-konseling">
                                Bimbingan Konseling
                              </Link>
                            </li>
                            <li>
                              <Link to="/content/layanan-mahasiswa">
                                Layanan Mahasiswa
                              </Link>
                            </li>
                            <li>
                              <Link to="https://bit.ly/LayananPengaduanFamul ">
                                Layanan Pengaduan
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <Link to="#">
                            Alumni <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li>
                              <Link
                                to="https://perkasa.unmul.ac.id/mahasiswa/login-mahasiswa"
                                target="_blank"
                              >
                                Tracer Study
                              </Link>
                            </li>
                          </ul>
                        </li>

                        {/* <li className="dropdown">
                                                <Link to="#">PPID</Link>
                                                <ul>
                                                    <li><Link to="/ppid/informasi-wajib-berkala">Informasi Wajib <br /> Berkala</Link></li>
                                                    <li><Link to="/ppid/informasi-tersedia-setiap-saat">Informasi <br /> Tersedia  <br />Setiap Saat</Link></li>
                                                    <li><Link to="/ppid/informasi-yang-dikecualikan">Informasi yang Dikecualikan</Link></li>
                                                    <li><Link to="/ppid/informasi-diumumkan-serta-merta">Informasi <br /> Diumumkan <br /> Serta Merta</Link></li>
                                                    <li><Link to="/ppid/informasi-regulasi">Informasi <br /> Regulasi</Link></li>
                                                    
                                                </ul>       
                                            </li> */}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        className={`stricky-header stricked-menu main-menu ${
          isSticky ? "stricky-fixed" : ""
        }`}
      >
        <div class="sticky-header__content">
          <div class="main-menu__inner">
            <a href="#" class="mobile-nav__toggler">
              <i class="fa fa-bars"></i>
            </a>

            <div className="stricky-one-logo">
              <div className="logo">
                <Link to="/">
                  <img
                    className="dark-logo"
                    style={{ maxWidth: "100px" }}
                    src="/assets/images/resources/unmul-blu.png"
                    alt=""
                  />
                  <img
                    className="light-logo"
                    src="/assets/images/resources/logo-2.png"
                    alt=""
                  />
                </Link>
              </div>
            </div>

            <div class="main-header--one__bottom-left">
              <ul class="main-menu__list">
                {/* <li><a href="/">Beranda</a></li> */}

                <li class="dropdown">
                  <a href="#">
                    Profil <i class="fa fa-angle-down"></i>
                  </a>
                  <ul>
                    <li>
                      <Link to="/sejarah">Sejarah</Link>
                    </li>
                    <li>
                      <Link to="/visi-misi">Visi dan Misi</Link>
                    </li>
                    <li>
                      <Link to="/pimpinan-fakultas">Pimpinan Fakultas</Link>
                    </li>
                    <li>
                      <Link to="/struktur-organisasi">Struktur Organisasi</Link>
                    </li>
                    <li>
                      <Link to="/dosen">Dosen</Link>
                    </li>
                    <li>
                      <Link to="/tendik">Tenaga Kependidikan</Link>
                    </li>
                    <li>
                      <Link to="/zona-integritas">Zona Integritas</Link>
                    </li>
                  </ul>
                </li>

                <li class="dropdown">
                  <a href="#">
                    Akademik <i class="fa fa-angle-down"></i>
                  </a>
                  <ul>
                    <li>
                      <Link to="/penelitian">Penelitian</Link>
                    </li>
                    <li>
                      <Link to="/pengabdian">Pengabdian</Link>
                    </li>
                    {/* <li
                      className="dropdown ml-0 pl-0"
                      style={{ marginLeft: 0 }}
                    >
                      <Link to="#">Pendidikan</Link>
                      <ul>
                        <li>
                          <Link to="/prodi/diploma">Diploma</Link>
                        </li>
                        <li>
                          <Link to="/prodi/s1-farmasi">S1 Farmasi</Link>
                        </li>
                        <li>
                          <Link to="/prodi/s1-farmasi-klinis">
                            S1 Farmasi Klinis
                          </Link>
                        </li>
                        <li>
                          <Link to="/prodi/profesi-apoteker">
                            Profesi Apoteker
                          </Link>
                        </li>
                      </ul>
                    </li> */}

                    <li>
                      <Link to="/buku-dan-riset">Buku dan Riset</Link>
                    </li>

                    <li>
                      <Link to="/kalender-akademik">Kalender Akademik</Link>
                    </li>
                    <li>
                      <Link to="/biaya-pendidikan">Biaya Pendidikan</Link>
                    </li>

                    <li>
                      <Link to="/">KEPK</Link>
                      <ul>
                        <li>
                          <Link to="https://sim-epk.farmasi.unmul.ac.id/">
                            Website KEPK
                          </Link>
                        </li>
                        <li>
                          <Link to="/kepk">Informasi</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="https://pmb.unmul.ac.id/">
                        Pendaftaran Mahasiswa Baru
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="dropdown">
                  <a href="#">
                    Program Studi <i class="fa fa-angle-down"></i>
                  </a>
                  <ul>
                    <li>
                      <Link to="/prodi/diploma">Diploma</Link>
                    </li>
                    <li>
                      <Link to="/prodi/s1-farmasi">S1 Farmasi</Link>
                    </li>
                    <li>
                      <Link to="/prodi/s1-farmasi-klinis">
                        S1 Farmasi Klinis
                      </Link>
                    </li>
                    <li>
                      <Link to="/prodi/profesi-apoteker">Profesi Apoteker</Link>
                    </li>
                  </ul>
                </li>

                <li class="dropdown">
                  <a href="#">
                    Penjaminan Mutu <i class="fa fa-angle-down"></i>
                  </a>
                  <ul>
                    <li>
                      <Link to="/tentang-penjamin-mutu-fakultas">
                        Tentang Penjamin Mutu Fakultas
                      </Link>
                    </li>

                    <li>
                      <Link to="https://drive.google.com/drive/folders/1WwtrqmX9fxXSGIagSXR2XAIVkiA8o834">
                        SPMI
                      </Link>
                    </li>

                    <li>
                      <Link to="https://drive.google.com/drive/folders/1oDLvPQ-iQBQNraK8nbNioZi3TVABGI5n">
                        RTM (Rapat Tinjauan Manajemen)
                      </Link>
                    </li>

                    <li>
                      <Link to="https://drive.google.com/drive/folders/1rHOj3_m-IvzNBeLUiF8C07mAlyuYZQ_o?usp=sharing">
                        Laporan Audit Mutu Internal
                      </Link>
                    </li>
                    <li>
                      <Link to="/kriteria-data-akreditasi">
                        Kriteria Data Akreditasi
                      </Link>
                    </li>
                    <li>
                      <Link to="/survey-kepuasan-civitas">
                        Survey Kepuasan Civitas Akademika
                      </Link>
                    </li>
                  </ul>
                </li>

                <li class="dropdown">
                  <a href="#">
                    Fasilitas <i class="fa fa-angle-down"></i>
                  </a>
                  <ul>
                    <li>
                      <Link to="/content/ruang-teater">Ruang Teater</Link>
                    </li>
                    <li>
                      <Link to="/content/perpustakaan">Perpustakaan</Link>
                    </li>
                    <li>
                      <Link to="/content/smart-classroom">Smart Classroom</Link>
                    </li>
                    <li>
                      <Link to="/content/ruang-kuliah-farmasi">
                        Ruang Kuliah Farmasi
                      </Link>
                    </li>
                    <li>
                      <Link to="/content/mushola">Mushola</Link>
                    </li>
                    <li
                      className="dropdown ml-0 pl-0"
                      style={{ marginLeft: 0 }}
                    >
                      <Link to="#">Laboratorium</Link>
                      <ul>
                        <li>
                          <Link to="/content/laboratorium-farmakognosi--kimia-medisinal">
                            Lab Farmakognosi - Kimia Medisinal
                          </Link>
                        </li>
                        <li>
                          <Link to="/content/laboratorium-farmasetika">
                            Lab Farmasetika
                          </Link>
                        </li>
                        <li>
                          <Link to="/content/laboratorium-farmakologi">
                            Lab Farmakologi
                          </Link>
                        </li>
                        <li>
                          <Link to="/content/laboratorium-farmasi-klinik-dan-komunitas">
                            Lab Farmasi Klinik dan Komunitas
                          </Link>
                        </li>
                        <li>
                          <Link to="/content/laboratorium-umum-pendidikan-penelitian-kefarmasian">
                            Lab Umum Pendidikan dan Penelitian Kefarmasian
                          </Link>
                        </li>
                        <li>
                          <Link to="/content/laboratorium-praktek-kerja-kefarmasian">
                            Lab Praktek Kerja Kefarmasian
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li class="dropdown">
                  <a href="#">
                    Informasi <i class="fa fa-angle-down"></i>
                  </a>
                  <ul>
                    <li>
                      <Link to="/berita">Berita</Link>
                    </li>
                    <li>
                      <Link to="/pengumuman">Pengumuman</Link>
                    </li>
                    <li>
                      <Link to="/galeri">Galeri</Link>
                    </li>
                    <li>
                      <Link to="/acara">Acara & Agenda</Link>
                    </li>

                    <li>
                      <Link to="/akreditasi">Akreditas</Link>
                    </li>

                    <li>
                      <Link to="/perpustakaan">Perpustakaan</Link>
                    </li>
                    <li>
                      <Link to="/informasi-regulasi">Regulasi</Link>
                    </li>
                    <li>
                      <Link to="/content/beasiswa">Beasiswa</Link>
                    </li>
                    <li>
                      <Link to="/mitra-kerjasama">Mitra Kerjasama</Link>
                    </li>
                  </ul>
                </li>

                <li className="dropdown">
                  <Link to="#">
                    Kemahasiswaan <i class="fa fa-angle-down"></i>
                  </Link>
                  <ul>
                    <li>
                      <Link to="/content/bimbingan-konseling">
                        Bimbingan Konseling
                      </Link>
                    </li>
                    <li>
                      <Link to="/content/layanan-mahasiswa">
                        Layanan Mahasiswa
                      </Link>
                    </li>
                    <li>
                      <Link to="https://bit.ly/LayananPengaduanFamul ">
                        Layanan Pengaduan
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="dropdown">
                  <Link to="#">
                    Alumni <i class="fa fa-angle-down"></i>
                  </Link>
                  <ul>
                    <li>
                      <Link
                        to="https://perkasa.unmul.ac.id/mahasiswa/login-mahasiswa"
                        target="_blank"
                      >
                        Tracer Study
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li class="dropdown">
												<a href="#">PPID</a>
                                                <ul>
                                                    <li><Link to="/ppid/informasi-wajib-berkala">Informasi Wajib <br /> Berkala</Link></li>
                                                    <li><Link to="/ppid/informasi-tersedia-setiap-saat">Informasi <br /> Tersedia  <br />Setiap Saat</Link></li>
                                                    <li><Link to="/ppid/informasi-yang-dikecualikan">Informasi yang Dikecualikan</Link></li>
                                                    <li><Link to="/ppid/informasi-diumumkan-serta-merta">Informasi <br /> Diumumkan <br /> Serta Merta</Link></li>
                                                    <li><Link to="/ppid/informasi-regulasi">Informasi <br /> Regulasi</Link></li>
                                                </ul>   
											</li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={`mobile-nav__wrapper ${isExpanded ? "expanded" : ""}`}>
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>

        <div className="mobile-nav__content">
          <span
            className="mobile-nav__close mobile-nav__toggler"
            onClick={handleClose}
          >
            <i className="fa fa-times"></i>
          </span>

          <div className="logo-box">
            <a href="index.html" aria-label="logo image">
              <img
                src="/assets/images/resources/unmul-blu.png"
                width="155"
                alt=""
              />
            </a>
          </div>

          <div className="mobile-nav__container">
            <ul class="main-menu__list">
              {/* <li><Link to="/">Beranda</Link></li> */}
              <li
                className={`dropdown ${
                  expandedMenu === "profil" ? "expanded" : ""
                }`}
              >
                <a
                  href="#"
                  className={expandedMenu === "profil" ? "expanded" : ""}
                >
                  Profil
                  <button
                    onClick={() => handleToggleList("profil")}
                    aria-label="dropdown toggler"
                    class=""
                  >
                    <i class="fa fa-angle-down"></i>
                  </button>
                </a>

                <ul
                  style={{
                    display: expandedMenu === "profil" ? "block" : "none",
                  }}
                >
                  <li>
                    <Link to="/visi-misi">Visi dan Misi</Link>
                  </li>
                  <li>
                    <Link to="/dosen">Dosen</Link>
                  </li>
                  <li>
                    <Link to="/sejarah">Sejarah</Link>
                  </li>
                  <li>
                    <Link to="/tendik">Tenaga Kependidikan</Link>
                  </li>
                  <li>
                    <Link to="/struktur-organisasi">Struktur Organisasi</Link>
                  </li>
                  <li>
                    <Link to="/pimpinan-fakultas">Pimpinan Fakultas</Link>
                  </li>
                  <li>
                    <Link to="/zona-integritas">Zona Integritas</Link>
                  </li>
                </ul>
              </li>

              <li
                className={`dropdown ${
                  expandedMenu === "akademik" ? "expanded" : ""
                }`}
              >
                <a
                  href="#"
                  className={expandedMenu === "akademik" ? "expanded" : ""}
                >
                  Akademik
                  <button
                    onClick={() => handleToggleList("akademik")}
                    aria-label="dropdown toggler"
                    class=""
                  >
                    <i class="fa fa-angle-down"></i>
                  </button>
                </a>
                <ul
                  style={{
                    display: expandedMenu === "akademik" ? "block" : "none",
                  }}
                >
                  <li>
                    <Link to="/penelitian">Penelitian</Link>
                  </li>
                  <li>
                    <Link to="/pengabdian">Pengabdian</Link>
                  </li>
                  {/* <li
                    className="dropdown ml-0 pl-0"
                    style={{ marginLeft: 0 }}
                    onClick={() => setIsOpenEducation(!isOpenEducation)}
                  >
                    <Link to="#">Pendidikan</Link>
                  </li>

                  {isOpenEducation && (
                    <>
                      <li className="ml-3">
                        <Link to="/prodi/diploma">Diploma</Link>
                      </li>
                      <li className="ml-3">
                        <Link to="/prodi/s1-farmasi">S1 Farmasi</Link>
                      </li>
                      <li className="ml-3">
                        <Link to="/prodi/s1-farmasi-klinis">
                          S1 Farmasi Klinis
                        </Link>
                      </li>
                      <li className="ml-3">
                        <Link to="/prodi/profesi-apoteker">
                          Profesi Apoteker
                        </Link>
                      </li>
                    </>
                  )} */}

                  <li>
                    <Link to="/buku-dan-riset">Buku dan Riset</Link>
                  </li>

                  <li>
                    <Link to="/kalender-akademik">Kalender Akademik</Link>
                  </li>
                  <li>
                    <Link to="/biaya-pendidikan">Biaya Pendidikan</Link>
                  </li>

                  <li>
                    <Link to="/">KEPK</Link>
                    <ul>
                      <li>
                        <Link to="https://sim-epk.farmasi.unmul.ac.id/">
                          Website KEPK
                        </Link>
                      </li>
                      <li>
                        <Link to="/kepk">Informasi</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="https://pmb.unmul.ac.id/">
                      Pendaftaran Mahasiswa Baru
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className={`dropdown ${
                  expandedMenu === "program-studi" ? "expanded" : ""
                }`}
              >
                <a
                  href="#"
                  className={expandedMenu === "program-studi" ? "expanded" : ""}
                >
                  Program Studi
                  <button
                    onClick={() => handleToggleList("program-studi")}
                    aria-label="dropdown toggler"
                    class=""
                  >
                    <i class="fa fa-angle-down"></i>
                  </button>
                </a>
                <ul
                  style={{
                    display:
                      expandedMenu === "program-studi" ? "block" : "none",
                  }}
                >
                  <li>
                    <Link to="/prodi/diploma">Diploma</Link>
                  </li>
                  <li>
                    <Link to="/prodi/s1-farmasi">S1 Farmasi</Link>
                  </li>
                  <li>
                    <Link to="/prodi/s1-farmasi-klinis">S1 Farmasi Klinis</Link>
                  </li>
                  <li>
                    <Link to="/prodi/profesi-apoteker">Profesi Apoteker</Link>
                  </li>
                </ul>
              </li>

              <li
                className={`dropdown ${
                  expandedMenu === "penjaminan-mutu" ? "expanded" : ""
                }`}
              >
                <a
                  href="#"
                  className={
                    expandedMenu === "penjaminan-mutu" ? "expanded" : ""
                  }
                >
                  Penjaminan Mutu
                  <button
                    onClick={() => handleToggleList("penjaminan-mutu")}
                    aria-label="dropdown toggler"
                    class=""
                  >
                    <i class="fa fa-angle-down"></i>
                  </button>
                </a>
                <ul
                  style={{
                    display:
                      expandedMenu === "penjaminan-mutu" ? "block" : "none",
                  }}
                >
                  <li>
                    <Link to="/tentang-penjamin-mutu-fakultas">
                      Tentang Penjamin Mutu Fakultas
                    </Link>
                  </li>

                  <li>
                    <Link to="https://drive.google.com/drive/folders/1WwtrqmX9fxXSGIagSXR2XAIVkiA8o834">
                      SPMI
                    </Link>
                  </li>

                  <li>
                    <Link to="https://drive.google.com/drive/folders/1oDLvPQ-iQBQNraK8nbNioZi3TVABGI5n">
                      RTM (Rapat Tinjauan Manajemen)
                    </Link>
                  </li>

                  <li>
                    <Link to="https://drive.google.com/drive/folders/1rHOj3_m-IvzNBeLUiF8C07mAlyuYZQ_o?usp=sharing">
                      Laporan Audit Mutu Internal
                    </Link>
                  </li>
                  <li>
                    <Link to="/kriteria-data-akreditasi">
                      Kriteria Data Akreditasi
                    </Link>
                  </li>
                  <li>
                    <Link to="/survey-kepuasan-civitas">
                      Survey Kepuasan Civitas Akademika
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className={`dropdown ${
                  expandedMenu === "fasilitas" ? "expanded" : ""
                }`}
              >
                <a
                  href="#"
                  className={expandedMenu === "fasilitas" ? "expanded" : ""}
                >
                  Fasilitas
                  <button
                    onClick={() => handleToggleList("fasilitas")}
                    aria-label="dropdown toggler"
                    class=""
                  >
                    <i class="fa fa-angle-down"></i>
                  </button>
                </a>
                <ul
                  style={{
                    display: expandedMenu === "fasilitas" ? "block" : "none",
                  }}
                >
                  <li>
                    <Link to="/content/ruang-teater">Ruang Teater</Link>
                  </li>
                  <li>
                    <Link to="/content/perpustakaan">Perpustakaan</Link>
                  </li>
                  <li>
                    <Link to="/content/smart-classroom">Smart Classroom</Link>
                  </li>
                  <li>
                    <Link to="/content/ruang-kuliah-farmasi">
                      Ruang Kuliah Farmasi
                    </Link>
                  </li>
                  <li>
                    <Link to="/content/mushola">Mushola</Link>
                  </li>

                  <li
                    className="dropdown ml-0 pl-0"
                    style={{ marginLeft: 0 }}
                    onClick={() => setIsOpenLab(!isOpenLab)}
                  >
                    <Link to="#">Laboratorium</Link>
                  </li>

                  {isOpenLab && (
                    <>
                      <li className="ml-3 p-2">
                        <Link to="/content/laboratorium-farmakognosi--kimia-medisinal">
                          Lab Farmakognosi - Kimia Medisinal
                        </Link>
                      </li>
                      <li className="ml-3 p-2">
                        <Link to="/content/laboratorium-farmasetika">
                          Lab Farmasetika
                        </Link>
                      </li>
                      <li className="ml-3 p-2">
                        <Link to="/content/laboratorium-farmakologi">
                          Lab Farmakologi
                        </Link>
                      </li>
                      <li className="ml-3 p-2">
                        <Link to="/content/laboratorium-farmasi-klinik-dan-komunitas">
                          Lab Farmasi Klinik dan Komunitas
                        </Link>
                      </li>
                      <li className="ml-3 p-2">
                        <Link to="/content/laboratorium-umum-pendidikan-penelitian-kefarmasian">
                          {" "}
                          Lab Umum Pendidikan dan Penelitian Kefarmasian
                        </Link>
                      </li>
                      <li className="ml-3 p-2">
                        <Link to="/content/laboratorium-praktek-kerja-kefarmasian">
                          {" "}
                          Lab Praktek Kerja Kefarmasian
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>

              <li
                className={`dropdown ${
                  expandedMenu === "informasi" ? "expanded" : ""
                }`}
              >
                <a
                  href="#"
                  className={expandedMenu === "informasi" ? "expanded" : ""}
                >
                  Informasi
                  <button
                    onClick={() => handleToggleList("informasi")}
                    aria-label="dropdown toggler"
                    class=""
                  >
                    <i class="fa fa-angle-down"></i>
                  </button>
                </a>
                <ul
                  style={{
                    display: expandedMenu === "informasi" ? "block" : "none",
                  }}
                >
                  <li>
                    <Link to="/berita">Berita</Link>
                  </li>
                  <li>
                    <Link to="/pengumuman">Pengumuman</Link>
                  </li>
                  <li>
                    <Link to="/galeri">Galeri</Link>
                  </li>
                  <li>
                    <Link to="/acara">Acara & Agenda</Link>
                  </li>

                  <li>
                    <Link to="/akreditasi">Akreditas</Link>
                  </li>

                  <li>
                    <Link to="/perpustakaan">Perpustakaan</Link>
                  </li>
                  <li>
                    <Link to="/informasi-regulasi">Regulasi</Link>
                  </li>
                  <li>
                    <Link to="/content/beasiswa">Beasiswa</Link>
                  </li>
                  <li>
                    <Link to="/mitra-kerjasama">Mitra Kerjasama</Link>
                  </li>
                </ul>
              </li>

              <li
                className={`dropdown ${
                  expandedMenu === "kemahasiswaan" ? "expanded" : ""
                }`}
              >
                <a
                  href="#"
                  className={expandedMenu === "kemahasiswaan" ? "expanded" : ""}
                >
                  kemahasiswaan
                  <button
                    onClick={() => handleToggleList("kemahasiswaan")}
                    aria-label="dropdown toggler"
                    class=""
                  >
                    <i class="fa fa-angle-down"></i>
                  </button>
                </a>
                <ul
                  style={{
                    display:
                      expandedMenu === "kemahasiswaan" ? "block" : "none",
                  }}
                >
                  <li>
                    <Link to="/content/bimbingan-konseling">
                      Bimbingan Konseling
                    </Link>
                  </li>
                  <li>
                    <Link to="/content/layanan-mahasiswa">
                      Layanan Mahasiswa
                    </Link>
                  </li>
                  <li>
                    <Link to="https://bit.ly/LayananPengaduanFamul ">
                      Layanan Pengaduan
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className={`dropdown ${
                  expandedMenu === "alumni" ? "expanded" : ""
                }`}
              >
                <a
                  href="#"
                  className={expandedMenu === "alumni" ? "expanded" : ""}
                >
                  Alumni
                  <button
                    onClick={() => handleToggleList("alumni")}
                    aria-label="dropdown toggler"
                    class=""
                  >
                    <i class="fa fa-angle-down"></i>
                  </button>
                </a>
                <ul
                  style={{
                    display: expandedMenu === "alumni" ? "block" : "none",
                  }}
                >
                  <li>
                    <Link
                      to="https://perkasa.unmul.ac.id/mahasiswa/login-mahasiswa"
                      target="_blank"
                    >
                      Tracer Study
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="icon-phone-call"></i>
              <a href="tel:0541-739491">0541-739491</a>
            </li>

            <li>
              <i className="icon-phone-call"></i>
              <a href="tel:0541-7273848">0541-7273848</a>
            </li>

            <li>
              <i className="icon-letter"></i>
              <a href="mailto:fa_mul@farmasi.unmul.ac.id">
                fa_mul@farmasi.unmul.ac.id
              </a>
            </li>
          </ul>
          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <a
                href="https://x.com/farmasiunmul"
                className="fab fa-twitter"
              ></a>
              <a
                href="https://web.facebook.com/profile.php?id=179449648732948&paipv=0&eav=AfYw3nG7qL7y4sar7XjObCuYkZQlsCrZT7xlfr-Fonbw4m2v9Heb1rTWZ0y_ptmX8-A&_rdc=1&_rdr"
                className="fab fa-facebook-square"
              ></a>
              <a
                href="https://www.youtube.com/@farmatroptv967"
                className="fab fa-youtube"
              ></a>
              <a
                href="https://www.instagram.com/farmasi_unmul/"
                className="fab fa-instagram"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
