import React from 'react';
import MainLayout from '../../layout/MainLayout.tsx';
import PageHeader from '../../components/PageHeader.tsx';
import ServiceForm from './components/ServiceForm.tsx';

export default function SholarshipStep() {
  return (
    <>
      <MainLayout children={undefined}>

        <PageHeader title="Transkrip Sementara KHS, Surat Keterangan Alumni Fakultas Farmasi Unmul, Form Bebas Administrasi" breadcrumb={[{ label: "Beranda", link: "/" }, { label: "layanan Mahasiswa", link: "/content/layanan-mahasiswa" }, { label: "Transkrip Sementara KHS, Surat Keterangan Alumni Fakultas Farmasi Unmul, Form Bebas Administrasi", link: "/content/transkrip-sementara-khs-surat-keterangan-alumni-fakultas-farmasi-unmul-form-bebas-administrasi" }]}></PageHeader>


        <section class="projects-details">
          <div class="container">
            <div class="row">
              <h2 style={{ textAlign: "center", marginBottom: "2rem" }}>Alur Pengajuan Transkrip Sementara</h2>
              <div class="col-xl-12">
                <div class="projects-details__wrapper">
                  <div class="projects-details__img">
                    <img src="/assets/images/resources/transkrip-sementara.webp" style={{ width: "100%" }} alt="" />
                  </div>




                </div>
              </div>
            </div>
          </div>
        </section>



        <ServiceForm type="pengajuan-transkrip-sementara"></ServiceForm>
      </MainLayout>
    </>
  );
}
