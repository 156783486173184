import React from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";
import { Helmet } from "react-helmet-async";

export default function Schoolarship() {
  return (
    <>
      <Helmet>
        <title>
          Beasiswa FF Unmul - Daftar Beasiswa Nasional, Daerah, dan Pasca
          Sarjana
        </title>
        <meta
          name="description"
          content="Temukan berbagai program beasiswa nasional, daerah, dan pasca sarjana di Indonesia untuk tahun 2024."
        />
        <meta
          name="keywords"
          content="beasiswa, beasiswa nasional, beasiswa daerah, beasiswa pasca sarjana, beasiswa Indonesia 2024"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Beasiswa 2024 - Daftar Beasiswa Nasional, Daerah, dan Pasca Sarjana"
        />
        <meta
          property="og:description"
          content="Dapatkan informasi beasiswa terbaru di Indonesia, termasuk beasiswa nasional, daerah, dan pasca sarjana."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://ff.unmul.ac.id/#/content/beasiswa"
        />
        <meta
          property="og:image"
          content="https://ff.unmul.ac.id/#/content/beasiswa"
        />
      </Helmet>
      <MainLayout children={undefined}>
        <PageHeader
          title="Beasiswa"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: "Beasiswa", link: "/content/beasiswa" },
          ]}
        ></PageHeader>

        <section class="services-details">
          <div class="container">
            <div class="row">
              <div class="col-xl-4 col-lg-4">
                <div class="services-details__sidebar">
                  <div class="services-details__sidebar-single">
                    <div
                      class="services-details__sidebar-single-services wow fadeInUp animated animated animated"
                      data-wow-delay="0.1s"
                      data-wow-duration="1200m"
                    >
                      <div class="title">
                        <h3>Beasiswa Nasional</h3>
                      </div>
                      <ul>
                        <li>
                          <a href="https://kip-kuliah.kemdikbud.go.id/">
                            Kartu Indonesia Pintar (KIP){" "}
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://bim-pusatprestasinasional.kemdikbud.go.id/">
                            Beasiswa Indonesia Maju{" "}
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://beasiswa.kemdikbud.go.id/">
                            Beasiswa Pendidikan Indonesia{" "}
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://beasiswaunggulan.kemdikbud.go.id/">
                            Beasiswa Unggulan <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://beasiswa.kemenag.go.id/">
                            Beasiswa Indonesia Bangkit{" "}
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://beasiswa.baznas.go.id/">
                            Beasiswa Basnaz <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4">
                <div class="services-details__sidebar">
                  <div class="services-details__sidebar-single">
                    <div
                      class="services-details__sidebar-single-services wow fadeInUp animated animated animated"
                      data-wow-delay="0.1s"
                      data-wow-duration="1200m"
                    >
                      <div class="title">
                        <h3>Beasiswa Daerah</h3>
                      </div>
                      <ul>
                        <li>
                          <a href="https://beasiswa.kaltimprov.go.id/">
                            Beasiswa Kalimantan Timur (BKT){" "}
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://beasiswakaltaraunggul.kaltaraprov.go.id/">
                            Beasiswa Kalimantan Timur{" "}
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://beasiswa.kutaitimurkab.go.id/">
                            Beasiswa Kutai Timur{" "}
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://beasiswa.balikpapan.go.id/">
                            Beasiswa Balikpapan{" "}
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://beasiswa.kukarkab.go.id/">
                            Beasiswa Kutai Kartanegara{" "}
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://e-beasiswa.bontangkota.go.id/">
                            Beasiswa Bontang <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://beasiswa.beraukab.go.id/">
                            Beasiswa Berau <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://kesra.paserkab.go.id/page/2/beasiswa-paser-berkilau">
                            Beasiswa Paser <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4">
                <div class="services-details__sidebar">
                  <div class="services-details__sidebar-single">
                    <div
                      class="services-details__sidebar-single-services wow fadeInUp animated animated animated"
                      data-wow-delay="0.1s"
                      data-wow-duration="1200m"
                    >
                      <div class="title">
                        <h3>Beasiswa Pasca Sarjana</h3>
                      </div>
                      <ul>
                        <li>
                          <a href="https://lpdp.kemenkeu.go.id/en/beasiswa/umum/beasiswa-reguler-2024/">
                            LPDP <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.pmdsu.id/">
                            PMDSU <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}
