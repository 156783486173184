import React, { useState, useEffect } from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';
import { Link } from 'react-router-dom';


const links = [
  {
    title: 'Surat Rekomendasi Beasiswa',
    link: '/content/surat-rekomendasi-beasiswa',
  },
  {
    title: 'SKL dan Sertifikat UKOM D3 dan S1 Farmasi Umum',
    link: '/content/skl-dan-sertifikat-ukom-d3-dan-s1-farmasi-umum',
  },
  {
    title: 'Surat Aktif Kuliah',
    link: '/content/surat-aktif-kuliah',
  },
  {
    title: 'SKL dan Sertifikat UKOM Profesi Apoteker dan S1 Farmasi Klinik',
    link: '/content/skl-dan-sertifikat-ukom-profesi-apoteker-dan-s1-farmasi-klinik',
  },
  {
    title: 'Transkrip Sementara KHS, Surat Keterangan Alumni Fakultas Farmasi Unmul, Form Bebas Administrasi',
    link: '/content/transkrip-sementara-khs-surat-keterangan-alumni-fakultas-farmasi-unmul-form-bebas-administrasi',
  },
  {
    title: 'Surat Keterangan Sedang Menyelesaikan Tugas Akhir',
    link: '/content/surat-keterangan-sedang-menyelesaikan-tugas-akhir',
  }, 
  {
    title: 'Surat Dispensasi',
    link: '/content/surat-dispensasi',
  },
  {
    title: 'Surat Cuti',
    link: '/content/surat-cuti',
  },
  {
    title: 'Peminjaman Ruangan untuk Sidang',
    link: '/content/peminjaman-ruangan-untuk-sidang',
  }, 
  {
    title: 'Peminjaman Teater Farmasi Unmul(Untuk Kegiatan)',
    link: '/content/peminjaman-teater-farmasi-unmul-untuk-kegiatan',
  }
]

export default function StudentService() {
 

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Layanan Mahasiswa"
          breadcrumb={[
            { label: 'Beranda', link: '/' },
            { label: 'Layanan Mahasiswa', link: '/content/layanan-mahasiswa' },
          ]}
        ></PageHeader>


    <section class="testimonials-page">
			<div class="container">
				<div class="row">

          {
            links.map((item, index) => (
              <div class="col-md-6 col-lg-4">
              
                <Link to={item.link}  class="testimonials-one__single"  >
              
                  <div class="testimonials-one__single-client-info">
                      <h4>{item.title}</h4>
                  </div>
                </Link>
            
              </div>
            ))
          }

	
				</div>
			</div>
		</section>

  
      </MainLayout>
    </>
  );
}
