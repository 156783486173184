import React, { useState, useEffect } from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";

export default function Books() {
  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Buku dan Riset"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: "Buku dan Riset", link: "/buku" },
          ]}
        ></PageHeader>
      </MainLayout>
    </>
  );
}
