import React from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';

export default function FarmacyStudyRoom() {
  return (
   <>
     <MainLayout children={undefined}>

        <PageHeader title="Ruang Kuliah Farmasi" breadcrumb={[{ label: "Beranda", link: "/" }, { label: "Ruang Kuliah Farmasi", link: "/ruang-kuliah-farmasi" }]}></PageHeader>


        <section class="projects-details">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="projects-details__wrapper">
                            <div class="projects-details__img">
                                <img className="h-56 sm:h-64 xl:h-80 2xl:h-96 object-cover object-center" src="/assets/images/resources/ruang-kuliah-farmasi.jpeg" style={{ width: "100%" } } alt="" />
                            </div>


                               <div className="projects-details__text-box1">
                                <p className="mt-4 text-justify">Smart Classroom Farmasi Universitas Mulawarman terletak di Gedung Farmasi-A dan Gedung Farmasi-C. Ruangan ini dilengkapi dengan ruangan pendingin, kursi dan meja, papan tulis, TV, Smart Board, dan komputer. Ruangan ini digunakan untuk kegiatan perkuliahan.</p>
                              </div> 

                     
                     
                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
       



        </MainLayout>
   </>
  );
}