import React from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";
import { Link } from "react-router-dom";

export default function InternalAuditQuality() {
  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Kriteria Data Akreditasi"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            {
              label: "Kriteria Data Akreditasi",
              link: "/kriteria-data-akreditasi",
            },
          ]}
        ></PageHeader>

        <section class="blog-one blog-one--news">
          <div
            class="blog-one__bg wow slideInDown"
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          ></div>
          <div class="container">
            <div className="row">
              <div className="col-sm-12 col-xl-4 col-lg-4  ">
                <div class="shop-one__sidebar__item shop-one__sidebar__category">
                  <h3 class="shop-one__sidebar__item__title text-[2rem] text-center mb-12">
                    S1 Farmasi
                  </h3>

                  <ul class="list-unstyled shop-one__sidebar__category__list">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                      <li>
                        <Link
                          key={item}
                          to={`/content/kriteria/${item}-1`}
                          className="text-2xl"
                        >
                          Kriteria {item}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="col-sm-12 col-xl-4 col-lg-4  ">
                <div class="shop-one__sidebar__item shop-one__sidebar__category">
                  <h3 class="shop-one__sidebar__item__title text-[2rem] text-center mb-12">
                    Apoteker
                  </h3>
                </div>
              </div>

              <div className="col-sm-12 col-xl-4 col-lg-4  ">
                <div class="shop-one__sidebar__item shop-one__sidebar__category">
                  <h3 class="shop-one__sidebar__item__title text-[2rem] text-center mb-12">
                    Diploma
                  </h3>

                  <ul class="list-unstyled shop-one__sidebar__category__list">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                      <li>
                        <Link
                          key={item}
                          to={`/content/kriteria/${item}`}
                          className="text-2xl"
                        >
                          Kriteria {item}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}
