import React, { useState } from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";
import { Pagination } from "flowbite-react";
const partners = [
  {
    id: 1,
    name: "Institut Teknologi Kesehatan dan Sains Wiyata Husada Samarinda",
  },
  {
    id: 2,
    name: "Fakultas Matematika dan Ilmu Pengetahuan Alam Universitas Islam Indonesia, Yogyakarta",
  },
  {
    id: 3,
    name: "Fakultas Ilmu Kesehatan Universitas Kusuma Husada Surakarta",
  },
  {
    id: 4,
    name: "Fakultas Sains dan Kesehatan Universitas PGRI Adi Buana Surabaya",
  },
  { id: 5, name: "Universiti Sains Malaysia" },
  { id: 6, name: "Management and Science University, Malaysia" },
  { id: 7, name: "Faculty Of Pharmacy, Mahidol University, Thailand" },
  {
    id: 8,
    name: "Fakultas Farmasi Universitas Nahdlatul Ulama Kalimantan Timur",
  },
  { id: 9, name: "Ikatan Apoteker Indonesia (IAI)" },
  { id: 10, name: "Akademi Farmasi Surabaya" },
  {
    id: 11,
    name: "Rumah Sakit Umum Daerah Dr. Kanudjoso Djatiwibowo Provinsi Kalimantan Timur",
  },
  {
    id: 12,
    name: "Rumah Sakit Puri Husada Tembilahan Kabupaten Indragiri Hilir",
  },
  { id: 13, name: "Rumah Sakit Samarinda Medika Citra" },
  { id: 14, name: "Apotek M. Yamin" },
  { id: 15, name: "Apotek Venus Farma" },
  { id: 16, name: "Apotek Hana Medika" },
  { id: 17, name: "Apotek Ibnu Rusyid" },
  { id: 18, name: "PT. Kimia Farma Apotek UB" },
  { id: 19, name: "UPTD. Puskesmas Samarinda Kota" },
  { id: 20, name: "UPTD. Puskesmas Sungai Siring" },
  { id: 21, name: "UPTD. Puskesmas Temindung" },
  { id: 22, name: "UPTD. Puskesmas Karang Asam" },
  { id: 23, name: "BPJS Ketenagakerjaan Cabang Samarinda" },
  { id: 24, name: "Koperasi Serba Usaha Mitra Farma" },
  { id: 25, name: "PT. Fajar Biofarmaka Nusantara" },
  { id: 26, name: "PT. Parit Padang Global" },
  { id: 27, name: "PT. EBM Saintifik dan Teknologi" },
  {
    id: 28,
    name: "Pusat Kolaborasi Riset Drugs Discovery dari Tumbuhan Meliaceae Indonesia",
  },
  { id: 29, name: "PT. Kimia Farma & Trading Distribution" },
  { id: 30, name: "Dinas Kesehatan Kota Samarinda" },
  { id: 31, name: "Dinas Kesehatan Kabupaten Kutai Timur" },
  { id: 32, name: "Dinas Kesehatan Kota Balikpapan" },
];

export default function Partner() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = partners.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(partners.length / itemsPerPage);

  const onPageChange = (page: number) => setCurrentPage(page);
  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Mitra Kerjasama"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: "Mitra Kerjasama", link: "/mitra-kerjasama" },
          ]}
        ></PageHeader>

        <section className="cart-table mb-24">
          <div className="container">
            <table>
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    No
                  </th>
                  <th scope="col" className="text-center">
                    Mitra Kerja Sama Fakultas Farmasi Universitas Mulawarman
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td data-label="No">{item.id}</td>
                    <td data-label="Nama Mitra">
                      <div className="cart-table__item flex flex-col items-center md:flex-row md:items-start">
                        <div className="ml-6 ">
                          <h3>{item.name}</h3>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex overflow-x-auto justify-center mt-6 ">
              <Pagination
                layout="pagination"
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                previousLabel=""
                nextLabel=""
                showIcons
              />
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}
