import React from 'react'

export default function Footer() {
  return (
    <footer className="footer-one">
    <div className="footer-one__top">
        <div className="container">
            <div className="row">
                <div className="col-xl-12">
                    <div className="footer-one__top-wrapper">
                       
                        <div className="row">
                          
                            <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
                                <div className="footer-widget__column footer-widget__about">
                                    <div className="footer-widget__about-logo">
                                        <a href="index.html"><img style={{ maxWidth: "150px" }}
                                                src="/assets/images/resources/unmul-blu.png" alt="" /></a>
                                    </div>
                                    <p className="footer-widget__about-text"><i className="fas fa-map-marker-alt" style={{ marginRight: "1rem" }}></i>Gedung Administrasi Farmasi, Jalan Penajam, Kampus Unmul Gunung Kelua, Kota Samarinda, 75119</p>
                                    <div className="footer-widget__about-contact-box">
                                        <p className="phone"><a href="tel:0541739491"><i
                                                    className="fas fa-phone-square-alt"></i>0541-739491</a></p>
                                         <p className="phone"><a href="tel:05417273848"><i
                                                    className="fas fa-phone-square-alt"></i>0541-7273848</a></p>
                                        <p><a href="mailto:fa_mul@farmasi.unmul.ac.id"><i
                                                    className="fa fa-envelope"></i>fa_mul@farmasi.unmul.ac.id</a></p>
                                       
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
                                <div className="footer-widget__column footer-widget__explore">
                                    <h2 className="footer-widget__title">Pranata Luar</h2>
                                    <ul className="footer-widget__explore-list">
                                        <li className="footer-widget__explore-list-item"><a href="https://ais.unmul.ac.id/">AIS (Academic Integrated System)</a></li>
                                        <li className="footer-widget__explore-list-item"><a href="https://unmul.ac.id/">Universitas Mulawarman</a></li>
                                        <li className="footer-widget__explore-list-item"><a href="https://wisuda.unmul.ac.id/">Wisuda</a></li>
                                        <li className="footer-widget__explore-list-item"><a href="https://ukt.unmul.ac.id/">UKT (Uang Kuliah Tunggal)</a></li>
                                        
                                    </ul>
                                </div>
                            </div>
                         
                            
                           
                            <div className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
                                <div className="footer-widget__column footer-widget__explore">
                                    <h2 className="footer-widget__title">Publikasi Jurnal</h2>
                                    <ul className="footer-widget__explore-list">
                                        <li className="footer-widget__explore-list-item"><a href="https://jtpc.ff.unmul.ac.id/">J. Trop. Pharm. Chem</a></li>
                                        <li className="footer-widget__explore-list-item"><a href="https://jsk.ff.unmul.ac.id/">J. Sains. Kes</a></li>
                                        <li className="footer-widget__explore-list-item"><a href="https://prosiding.ff.unmul.ac.id/">Proc. Mul. Pharm. Conf.</a></li>
                                        {/* <li className="footer-widget__explore-list-item"><a href="https://ff.unmul.ac.id/">Jurnal Dedikasi Mulawarman</a></li> */}
                                        
                                    </ul>
                                </div>
                            </div>
                           
                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
                                <div className="footer-widget__column footer-widget__newletter">
                                    <h2 className="footer-widget__title">Newletter</h2>
                                    <p className="footer-widget__newletter-text">Berlangganan untuk mendapatkan informasi terbaru dari kami</p>
                                    <form className="subscribe-form" action="#">
                                        <input type="email" name="email" placeholder="Alamat Email" />
                                        <button type="submit" >Go</button>
                                    </form>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="footer-one__bottom">
        <div className="container">
            <div className="row">
                <div className="col-xl-12">
                    <div className="footer-one__bottom-inner">
                        <div className="footer-one__bottom-text">
                            <p>&copy; Copyright 2024 by <a href="/">Fakultas Farmasi - Universitas Mulawarman</a></p>
                        </div>
                        <div className="footer-one__bottom-social-links">
                            <ul>
                                <li><a href="https://x.com/farmasiunmul"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="https://web.facebook.com/profile.php?id=179449648732948&paipv=0&eav=AfYw3nG7qL7y4sar7XjObCuYkZQlsCrZT7xlfr-Fonbw4m2v9Heb1rTWZ0y_ptmX8-A&_rdc=1&_rdr"><i className="fab fa-facebook"></i></a></li>
                                <li><a href="https://www.youtube.com/@farmatroptv967"><i className="fab fa-youtube"></i></a></li>
                                <li><a href="https://www.instagram.com/farmasi_unmul/"><i className="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  </footer>
  )
}
