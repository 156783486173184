import React, { useState, useEffect } from "react";
import Header from "../components/Header.tsx";
import Footer from "../components/Footer.tsx";
import { FloatingWhatsApp } from "react-floating-whatsapp";

interface MainLayoutProps {
  children: React.ReactNode;
}

export default function MainLayout({ children }: MainLayoutProps) {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = "auto";

    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <Header></Header>

        <main>{children}</main>

        <Footer></Footer>
      </div>

      <div className="search-popup">
        <div className="search-popup__overlay search-toggler"></div>
        <div className="search-popup__content">
          <form action="#">
            <label for="search" className="sr-only">
              search here
            </label>
            <input type="text" id="search" placeholder="Search Here..." />
            <button
              type="submit"
              aria-label="search submit"
              className="thm-btn2"
            >
              <i className="fa fa-search" aria-hidden="true"></i>
            </button>
          </form>
        </div>
      </div>

      <FloatingWhatsApp
        phoneNumber="+6285293010862"
        accountName="Humas FF Unmul"
        avatar="https://www.freeiconspng.com/thumbs/profile-icon-png/profile-icon-9.png"
      />

      <a href="#" data-target="html" class="scroll-to-target scroll-to-top">
        <i class="icon-right-arrow-2"></i>
      </a>
    </>
  );
}
