import React, { useState } from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";
import { Link } from "react-router-dom";
import lecturerData from "../data/lecturer.json";
import { Pagination } from "flowbite-react";

export default function Value() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = lecturerData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(lecturerData.length / itemsPerPage);

  const onPageChange = (page: number) => setCurrentPage(page);

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Dosen Kami"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: "Dosen", link: "/dosen" },
          ]}
        ></PageHeader>

        <section className="cart-table mb-24">
          <div className="container">
            <table>
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    Dosen
                  </th>
                  <th scope="col">Laboratorium</th>
                  <th scope="col" className="text-center">
                    Spesialisasi
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td data-label="Dosen">
                      <div className="cart-table__item flex flex-col items-end md:flex-row md:items-start">
                        <img
                          src={
                            item.image && item.image.trim() !== ""
                              ? item.image
                              : "https://www.freeiconspng.com/thumbs/profile-icon-png/profile-icon-9.png"
                          }
                          className="w-[125px] h-[125px] object-contain object-center"
                          alt={item.name}
                        />
                        <div className="ml-6 ">
                          <h3 className="font-bold">{item.name}</h3>
                          <p className="my-3">
                            {item.last_education}, {item.year_graduation}
                          </p>
                          <div className="flex gap-4">
                            {/* Sinta Link */}
                            {item.link_sinta ? (
                              <Link
                                to={item.link_sinta}
                                className="text-blue-600"
                              >
                                Sinta Ristekdikti
                              </Link>
                            ) : (
                              <span className="text-black cursor-default">
                                Sinta Ristekdikti
                              </span>
                            )}

                            <p className="text-black">|</p>

                            {/* Scopus Link */}
                            {item.link_scorpus ? (
                              <Link
                                to={item.link_scorpus}
                                className="text-blue-600"
                              >
                                Scopus
                              </Link>
                            ) : (
                              <span className="text-black cursor-default">
                                Scopus
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td data-label="Lab">{item.laboratorium}</td>
                    <td data-label="Spesialisasi text-center mx-auto">
                      <p className="max-w-xl text-center">
                        {item.specialization}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex overflow-x-auto justify-center mt-6 ">
              <Pagination
                layout="pagination"
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                previousLabel=""
                nextLabel=""
                showIcons
              />
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}
