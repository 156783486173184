import React, { useState } from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";

import staffData from "../data/staff.json";
import { Pagination } from "flowbite-react";

export default function Value() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = staffData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(staffData.length / itemsPerPage);

  const onPageChange = (page: number) => setCurrentPage(page);

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Tenaga Kependidikan"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: "Tenaga Kependidikan", link: "/tendik" },
          ]}
        ></PageHeader>

        <section className="cart-table mb-24">
          <div className="container">
            <table>
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    Nama Tenaga Kependidikan
                  </th>
                  <th scope="col" className="text-center">
                    Unit Bagian
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td data-label="Pegawai" className="text-center">
                      <div className="cart-table__item flex flex-col items-end md:flex-row md:items-start">
                        <img
                          src={
                            item.image && item.image.trim() !== ""
                              ? item.image
                              : "https://www.freeiconspng.com/thumbs/profile-icon-png/profile-icon-9.png"
                          }
                          className="w-[125px] h-[125px] object-contain object-center"
                          alt={item.name}
                        />
                        <div className="ml-6 ">
                          <h3 className="font-bold">{item.name}</h3>
                        </div>
                      </div>
                    </td>
                    {/* <td data-label="Lab">{item.laboratorium}</td> */}
                    <td data-label="Spesialisasi ">
                      <p className=" text-center max-w-[10rem] lg:mx-auto lg:max-w-[20rem]">
                        {item.unit}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex overflow-x-auto justify-center mt-6 ">
              <Pagination
                layout="pagination"
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                previousLabel=""
                nextLabel=""
                showIcons
              />
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}
