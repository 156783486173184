import React, { useState, useEffect } from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';

export default function KEPKInformation() {
 

  return (
    <>
         <MainLayout children={undefined}>
           <PageHeader
             title="Informasi KEPK"
             breadcrumb={[
               { label: 'Beranda', link: '/' },
               { label: 'Informasi KEPK', link: '/kepk' },
             ]}
           ></PageHeader>


        <section class="projects-details">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="projects-details__wrapper">
                            <div class="projects-details__img">
                                <img src="assets/images/resources/informasi-kepk.jpg"  style={{ width: "100%" }} alt="" />
                            </div>

                          

                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
     
         </MainLayout>
       </>
  );
}
