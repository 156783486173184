import React from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';

export default function InternalAuditQuality() {
  return (
   <>
     <MainLayout children={undefined}>

        <PageHeader title="Laporan Audit Mutu Internal" breadcrumb={[{ label: "Beranda", link: "/" }, { label: "Laporan Audit Mutu Internal", link: "/laporan-audit-mutu-internal" }]}></PageHeader>


        


        </MainLayout>
   </>
  );
}