import React from 'react';
import DiplomaLayout from './layouts/DiplomaLayout.tsx';
import { Carousel } from 'flowbite-react';

export default function Home() {
  return (
   <>
         <DiplomaLayout children={undefined}>

            
           <div className="h-[70vh]">
                                 <Carousel>
                                     
                                     
                                     {
                                         ['/assets/images/resources/background-kegiatan.jpg', '/assets/images/resources/image-tenaga-vokasi-farmasi.jpeg'].map((image, index) => (
                                             <div className="relative w-100 h-100">
                                                 <img
                                                     src={image}
                                                     alt="Background"
                                                     className="absolute w-full h-full object-cover z-0"
                                                 />
                             
                                             
                                                 <div className="absolute w-full h-full bg-[#1B7F49] opacity-50 z-10"></div>
                             
                                         
                                                 <div className="absolute container z-40 top-[20%] left-[5%] lg:top-[30%] lg:left-[20%]">
                                                     <h2 class="sec-title__title text-white text-4xl lg:text-5xl max-w-3xl p-6">Selamat datang di Website Prodi D3 Farmasi Universitas Mulawarman</h2>
                                                 </div>
                                             </div>
                                         ))
                                     }
                                 
                 
                             
                 
                                 </Carousel>    
            </div>
        

          
          
         

       
      

     

      </DiplomaLayout>
   </>
  );
}