import React, { useState, useEffect } from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';

export default function Alumni() {
 

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Alumni"
          breadcrumb={[
            { label: 'Beranda', link: '/' },
            { label: 'Alumni', link: '/alumni' },
          ]}
        ></PageHeader>

  
      </MainLayout>
    </>
  );
}
