import React, { useState, useEffect } from "react";
import MainLayout from "./../../layout/MainLayout.tsx";
import PageHeader from "./../../components/PageHeader.tsx";

import { Link } from "react-router-dom";
import { Pagination } from "flowbite-react";

import formattedDate from "../../utils/FormatDate.tsx";
import fetchAnnouncements from "../../hooks/FetchAnnouncement.ts";

export default function News() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [newsData, setNewsData] = useState<[]>([]);
  const [imagePaths, setImagePaths] = useState({});
  const [totalPages, setTotalPages] = useState<number>(100);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchAnnouncements(
      setLoading,
      setError,
      currentPage,
      itemsPerPage,
      setNewsData,
      setImagePaths
    );
  }, [currentPage]);

  useEffect(() => {
    if (!error) {
      console.log(newsData);
    }

    console.log("isi news data", newsData);
  }, [error, loading, newsData]);

  console.log(newsData);

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="pengumuman"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: "pengumuman", link: "/pengumuman" },
          ]}
        ></PageHeader>

        {loading ? (
          <div className="my-16 md:my-24 mx-auto flex flex-col md:flex-row justify-center items-center gap-6 max-w-screen-xl">
            {[1, 2, 3].map((item, index) => (
              <div
                key={index}
                role="status"
                class="flex-1 max-w-lg p-4 border border-gray-200 rounded-sm shadow-sm animate-pulse md:p-6"
              >
                <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded-sm ">
                  <svg
                    class="w-10 h-10 text-gray-200 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 20"
                  >
                    <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                  </svg>
                </div>
                <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full "></div>
                <div class="flex items-center mt-4">
                  <div>
                    <div class="h-2.5 bg-gray-200 rounded-full  w-32 mb-2"></div>
                    <div class="w-48 h-2 bg-gray-200 rounded-full "></div>
                  </div>
                </div>
                <span class="sr-only">Loading...</span>
              </div>
            ))}
          </div>
        ) : (
          <section class="blog-one blog-one--news">
            <div
              class="blog-one__bg wow slideInDown"
              data-wow-delay="100ms"
              data-wow-duration="2500ms"
            ></div>
            <div class="container">
              <div class="row">
                {newsData.map((item: any) => (
                  <div
                    className="col-xl-4 col-lg-4  wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="blog-one__single">
                      <div className="blog-one__single-img">
                        {imagePaths[item.id] ? (
                          <img
                            src={imagePaths[item.id]}
                            className="h-[300px] object-cover object-center"
                            alt={item.title_idn || "Gambar berita"}
                          />
                        ) : (
                          <p>Loading image...</p> // Tampilkan loading jika gambar belum tersedia
                        )}
                        <div className="date-box">
                          <span>{formattedDate(item.created_at)}</span>
                        </div>
                      </div>

                      <div className="blog-one__single-content min-h-[150px] max-h-[300px]">
                        <h2 className="text-lg uppercase">
                          <Link to={`/berita/detail/${item.slug}`}>
                            {item.title_idn.split(" ").slice(0, 5).join(" ") +
                              (item.title_idn.split(" ").length > 5
                                ? "..."
                                : "")}
                          </Link>
                        </h2>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="flex overflow-x-auto justify-center mt-6 ">
                  <Pagination
                    layout="pagination"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    previousLabel=""
                    nextLabel=""
                    showIcons
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </MainLayout>
    </>
  );
}
