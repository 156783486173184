/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Header from '../components/Header.tsx';
import Footer from '../../../../components/Footer.tsx';

interface MainLayoutProps {
  children: React.ReactNode;
}

export default function DiplomaLayout({ children }: MainLayoutProps) {
  return (
    <>
      <div className="page-wrapper">

          <Header></Header>

          <main>
            {children}
          </main>

         
         <Footer></Footer>


      </div>


     


      <div className="search-popup">
          <div className="search-popup__overlay search-toggler"></div>
          <div className="search-popup__content">
              <form action="#">
                  <label for="search" className="sr-only">search here</label>
                  <input type="text" id="search" placeholder="Search Here..." />
                  <button type="submit" aria-label="search submit" className="thm-btn2">
                      <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
              </form>
          </div>
      </div>

       <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="icon-right-arrow-2"></i></a>
      
    </>
  );
}
