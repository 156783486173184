import React, { useState, useEffect } from "react";
import MainLayout from "../layout/MainLayout.tsx";
import { Link } from "react-router-dom";

import { Carousel } from "flowbite-react";
import fetchProgramData from "../hooks/FetchProgram.ts";
import fetchNewsData from "../hooks/FetchNews.ts";
import formattedDate from "../utils/FormatDate.tsx";

export default function Home() {
  const [programData, setProgramData] = useState<[]>([]);
  const [imagePathsProgram, setImagePathsProgram] = useState({});

  const [newsData, setNewsData] = useState<[]>([]);
  const [imagePathsNews, setImagePathsNews] = useState({});

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchProgramData(
      setLoading,
      setError,
      1,
      3,
      setProgramData,
      setImagePathsProgram
    );

    fetchNewsData(setLoading, setError, 1, 3, setNewsData, setImagePathsNews);
  }, []);

  return (
    <>
      <MainLayout children={undefined}>
        <div className="h-[70vh]">
          <Carousel>
            {[
              "/assets/images/resources/background-kegiatan.jpg",
              "/assets/images/resources/image-tenaga-vokasi-farmasi.jpeg",
            ].map((image, index) => (
              <div className="relative w-100 h-100">
                <img
                  src={image}
                  alt="Background"
                  className="absolute w-full h-full object-cover object-center z-0"
                />

                <div className="absolute w-full h-full bg-[#1B7F49] opacity-50 z-10"></div>

                <div className="absolute container z-40 top-[20%] left-[5%] lg:top-[30%] lg:left-[20%]">
                  <h2 class="sec-title__title text-white text-4xl lg:text-5xl max-w-3xl p-6">
                    Selamat datang di Website Resmi Fakultas Farmasi Universitas
                    Mulawarman
                  </h2>
                </div>
              </div>
            ))}

            <div className="relative w-100 h-100">
              <img
                src="/assets/images/resources/gedung-ff-unmul.jpeg"
                alt="Background"
                className="absolute w-full h-full object-cover object-center z-0"
              />

              <div className="absolute w-full h-full bg-[#1B7F49] opacity-50 z-10"></div>

              <div className="absolute container z-40 top-[20%] left-[5%] lg:top-[30%] lg:left-[20%]">
                <h2 class="sec-title__title text-white text-4xl lg:text-5xl max-w-3xl p-6">
                  Selamat datang di Website Resmi Fakultas Farmasi Universitas
                  Mulawarman
                </h2>
              </div>
            </div>
          </Carousel>
        </div>

        <section class="product-details">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="product-details__content">
                  <div className="icon" style={{ marginBottom: "1rem" }}>
                    <img
                      src="assets/images/resources/unmul-blu.png"
                      style={{ maxWidth: "100px" }}
                      alt=""
                    />
                  </div>
                  <span className="sec-title__tagline">
                    Universitas Mulawarman
                  </span>

                  <div class="product-details__content__top mt-3">
                    <h2 class="sec-title__title">Tentang Kami</h2>
                  </div>

                  <div
                    class="product-details__content__text"
                    style={{ borderTop: "0" }}
                  >
                    <p style={{ fontWeight: "bold", marginBottom: "1rem" }}>
                      Assalamu’alaikum wr.wb,
                    </p>

                    <p>Salam Farmasis!</p>
                    <p>
                      Selamat datang di website resmi Fakultas Farmasi
                      Universitas Mulawarman. Platform ini kami hadirkan untuk
                      memperkenalkan visi, misi, dan program unggulan Fakultas
                      Farmasi.
                    </p>
                    <p>
                      Kami berkomitmen mencetak lulusan yang unggul secara
                      akademik, memiliki etika profesi yang kuat, serta siap
                      berkontribusi di bidang kesehatan.
                    </p>
                    <p>
                      Website ini dirancang untuk memberikan informasi lengkap
                      mengenai program studi, fasilitas, dan kegiatan
                      kemahasiswaan yang mendukung pengembangan mahasiswa.
                    </p>
                    <p>
                      Mari bersama mewujudkan masa depan gemilang di dunia
                      farmasi.
                    </p>
                    <p>
                      Terima kasih atas kunjungan Anda, semoga informasi di sini
                      bermanfaat!
                    </p>

                    <p style={{ fontWeight: "bold", marginTop: "1rem" }}>
                      Wassalamu’alaikum wr. wb
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="product-details__image"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src="assets/images/resources/tentang-kami-ff-unmul.png"
                    style={{ width: "75%" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="product-details">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div
                  class="product-details__image"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src="assets/images/resources/image-pengumuman-informasi.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="product-details__content">
                  <div class="product-details__content__top">
                    <h2 class="sec-title__title">Pengumuman dan Informasi</h2>
                  </div>

                  <div
                    class="product-details__content__text"
                    style={{ borderTop: "0" }}
                  >
                    <p>
                      Informasi terkait pendaftaran calon mahasiswa D3 Farmasi,
                      S1 Farmasi, S1 Farmasi Klinis, dan Profesi Apoteker
                      Fakultas Farmasi Universitas Mulawarman dapat klik tautan
                      di bawah ini
                    </p>
                  </div>
                  <button
                    type=""
                    style={{ marginTop: "2rem" }}
                    className="thm-btn comment-form__btn"
                  >
                    Selengkapnya
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="product-details">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="product-details__content">
                  <div class="product-details__content__top">
                    <h2 class="sec-title__title">Tentang Fakultas Farmasi</h2>
                  </div>

                  <div class="product-details__content__text">
                    <p>
                      Fakultas Farmasi Universitas Mulawarman bermula dari
                      inisiatif Dr. Laode Rijai pada tahun 2004 yang kemudian
                      didukung oleh Prof. Dr. Ir. H. Ach. Ariffien Bratawinata.
                      Meskipun sempat tertunda, program studi farmasi akhirnya
                      resmi dibuka pada tahun 2006 di bawah naungan Fakultas
                      Kesehatan Masyarakat. Seiring perkembangannya, program
                      studi ini kemudian berdiri sebagai unit pelaksana fakultas
                      yang mandiri. Pada tahun 2009, melalui surat keputusan
                      rektor, program studi farmasi ditetapkan sebagai cikal
                      bakal fakultas ke-12 di Universitas Mulawarman, menandai
                      tonggak penting dalam sejarah pendidikan farmasi di
                      Kalimantan Timur.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="product-details__image"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img src="assets/images/resources/tentang-ff.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features-two">
          <div className="container">
            <div className="d-flex justify-center align-items-center mb-6">
              <h2 class="sec-title__title mx-auto">Program Pendidikan</h2>
            </div>

            <div className="row">
              <div
                className=" col-xl-3 col-lg-6 col-md-6  wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div
                  className=" flex items-center justify-center flex-col features-two__single "
                  style={{ height: "250px" }}
                >
                  <div className="features-two__single-top">
                    <img
                      src="assets/images/resources/program-d3.svg"
                      style={{ margin: "auto" }}
                      alt=""
                    />
                  </div>
                  <div className="features-two__single-title">
                    <h3 style={{ textAlign: "center" }}>
                      <a href="#">Program D3 Farmasi</a>
                    </h3>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-3 col-lg-6 col-md-6  wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div
                  className=" flex items-center justify-center flex-col features-two__single "
                  style={{ height: "250px" }}
                >
                  <div className="features-two__single-top">
                    <img
                      src="assets/images/resources/s1-farmasi.svg"
                      style={{ margin: "auto" }}
                      alt=""
                    />
                  </div>
                  <div className="features-two__single-title">
                    <h3 style={{ textAlign: "center" }}>
                      <a href="#">
                        Program <br /> S1 Farmasi
                      </a>
                    </h3>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-3 col-lg-6 col-md-6  wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div
                  className=" flex items-center justify-center flex-col features-two__single "
                  style={{ height: "250px" }}
                >
                  <div className="features-two__single-top">
                    <img
                      src="assets/images/resources/s1-klinis.svg"
                      style={{ margin: "auto" }}
                      alt=""
                    />
                  </div>
                  <div className="features-two__single-title">
                    <h3 style={{ textAlign: "center" }}>
                      <a href="#">
                        Program <br /> S1 Farmasi Klinis
                      </a>
                    </h3>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-3 col-lg-6 col-md-6  wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div
                  className=" flex items-center justify-center flex-col features-two__single "
                  style={{ height: "250px" }}
                >
                  <div className="features-two__single-top">
                    <img
                      src="assets/images/resources/profesi-apoteker.svg"
                      style={{ margin: "auto" }}
                      alt=""
                    />
                  </div>
                  <div className="features-two__single-title">
                    <h3 style={{ textAlign: "center" }}>
                      <a href="#">
                        Program <br /> Profesi Apoteker
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="features-one features-one--services clearfix">
          <div class="container">
            <div className="sec-title text-center">
              <h2 className="sec-title__title">
                Kenapa harus farmasi Universitas Mulawarman?
              </h2>
              <p style={{ maxWidth: "800px", margin: "1rem auto" }}>
                Laboratorium lengkap dengan fasilitas modern mendukung
                praktikum, riset, dan pengembangan ilmu farmasi bagi mahasiswa
                dan peneliti.
              </p>
            </div>
            <div class="row">
              <div
                class="col-xl-4 col-lg-4 wow fadeInUp animated animated"
                style={{ marginBottom: "2rem" }}
              >
                <div class="features-one__single">
                  <div
                    class="features-one__single-img"
                    style={{ height: "300px", margin: "auto" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="assets/images/resources/icon-lab-1.svg"
                        style={{
                          height: "100px",
                          width: "200px",
                          objectFit: "contain",
                          objectPosition: "center",
                          marginBottom: "3rem",
                        }}
                        alt=""
                      />
                    </div>
                    <div class="features-one__single-title text-center">
                      <h3>
                        <Link to="/content/laboratorium-farmakognosi--kimia-medisinal">
                          Laboratorium Farmakognosi & Kimia Medisinal
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <Link
                    to="/content/laboratorium-farmakognosi--kimia-medisinal"
                    class="features-one__single__more"
                  >
                    <span class="icon-right-arrow-2"></span>
                  </Link>
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-4 wow fadeInUp animated animated"
                style={{ marginBottom: "2rem" }}
              >
                <div class="features-one__single">
                  <div
                    class="features-one__single-img"
                    style={{ height: "300px", margin: "auto" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="assets/images/resources/icon-lab-2.svg"
                        style={{
                          height: "100px",
                          width: "200px",
                          objectFit: "contain",
                          objectPosition: "center",
                          marginBottom: "3rem",
                        }}
                        alt=""
                      />
                    </div>
                    <div class="features-one__single-title text-center">
                      <h3>
                        <Link to="/content/laboratorium-farmasetika">
                          Laboratorium Farmasetika
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <Link
                    to="/content/laboratorium-farmasetika"
                    class="features-one__single__more"
                  >
                    <span class="icon-right-arrow-2"></span>
                  </Link>
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-4 wow fadeInUp animated animated"
                style={{ marginBottom: "2rem" }}
              >
                <div class="features-one__single">
                  <div
                    class="features-one__single-img"
                    style={{ height: "300px", margin: "auto" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="assets/images/resources/icon-lab-3.svg"
                        style={{
                          height: "100px",
                          width: "200px",
                          objectFit: "contain",
                          objectPosition: "center",
                          marginBottom: "3rem",
                        }}
                        alt=""
                      />
                    </div>
                    <div class="features-one__single-title text-center">
                      <h3>
                        <Link to="/content/laboratorium-farmakologi">
                          Laboratorium Farmakologi
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <Link
                    to="/content/laboratorium-farmakologi"
                    class="features-one__single__more"
                  >
                    <span class="icon-right-arrow-2"></span>
                  </Link>
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-4 wow fadeInUp animated animated"
                style={{ marginBottom: "2rem" }}
              >
                <div class="features-one__single">
                  <div
                    class="features-one__single-img"
                    style={{ height: "300px", margin: "auto" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="assets/images/resources/icon-lab-4.svg"
                        style={{
                          height: "100px",
                          width: "200px",
                          objectFit: "contain",
                          objectPosition: "center",
                          marginBottom: "3rem",
                        }}
                        alt=""
                      />
                    </div>
                    <div class="features-one__single-title text-center">
                      <h3>
                        <Link to="/content/laboratorium-farmasi-klinik-dan-komunitas">
                          Laboratorium Farmasi Klinik dan Komunitas
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <Link
                    to="/content/laboratorium-farmasi-klinik-dan-komunitas"
                    class="features-one__single__more"
                  >
                    <span class="icon-right-arrow-2"></span>
                  </Link>
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-4 wow fadeInUp animated animated"
                style={{ marginBottom: "2rem" }}
              >
                <div class="features-one__single">
                  <div
                    class="features-one__single-img"
                    style={{ height: "300px", margin: "auto" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="assets/images/resources/icon-lab-5.svg"
                        style={{
                          height: "100px",
                          width: "200px",
                          objectFit: "contain",
                          objectPosition: "center",
                          marginBottom: "6rem",
                        }}
                        alt=""
                      />
                    </div>
                    <div class="features-one__single-title text-center">
                      <h3>
                        <Link to="/content/laboratorium-umum-pendidikan-penelitian-kefarmasian">
                          Laboratorium Umum Pendidikan Penelitian Kefarmasian
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <Link
                    to="/content/laboratorium-umum-pendidikan-penelitian-kefarmasian"
                    class="features-one__single__more"
                  >
                    <span class="icon-right-arrow-2"></span>
                  </Link>
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-4 wow fadeInUp animated animated"
                style={{ marginBottom: "2rem" }}
              >
                <div class="features-one__single">
                  <div
                    class="features-one__single-img"
                    style={{ height: "300px", margin: "auto" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="assets/images/resources/icon-lab-6.svg"
                        style={{
                          height: "100px",
                          width: "200px",
                          objectFit: "contain",
                          objectPosition: "center",
                          marginBottom: "3rem",
                        }}
                        alt=""
                      />
                    </div>
                    <div class="features-one__single-title text-center">
                      <h3>
                        <Link to="/content/laboratorium-praktek-kerja-kefarmasian">
                          Laboratorium Praktek Kerja Kefarmasian
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <Link
                    to="/content/laboratorium-praktek-kerja-kefarmasian"
                    class="features-one__single__more"
                  >
                    <span class="icon-right-arrow-2"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-one">
          <div className="container">
            <div className="sec-title text-center">
              <span className="sec-title__tagline">Universitas Mulawarman</span>
              <h2 className="sec-title__title">Jurnal Farmasi</h2>
            </div>

            <div
              className="row "
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className=" col-xl-3 col-lg-6 col-md-6  wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div
                  className=" flex items-center justify-center flex-col features-two__single "
                  style={{ height: "300px" }}
                >
                  <div className="features-two__single-top">
                    <img
                      src="assets/images/resources/logo-journal/logo_jtpc.png"
                      style={{ margin: "auto" }}
                      alt=""
                    />
                  </div>
                  <div className="features-two__single-title">
                    <h3 style={{ textAlign: "center" }}>
                      <a href="https://jtpc.ff.unmul.ac.id/">
                        J. Trop. Pharm. Chem
                      </a>
                    </h3>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-3 col-lg-6 col-md-6  wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div
                  className=" flex items-center justify-center flex-col features-two__single "
                  style={{ height: "300px" }}
                >
                  <div className="features-two__single-top">
                    <img
                      src="assets/images/resources/logo-journal/logo_jsk.png"
                      style={{ margin: "auto" }}
                      alt=""
                    />
                  </div>
                  <div className="features-two__single-title">
                    <h3 style={{ textAlign: "center" }}>
                      <a href="https://jsk.ff.unmul.ac.id/">J. Sains. Kes</a>
                    </h3>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-3 col-lg-6 col-md-6  wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div
                  className=" flex items-center justify-center flex-col features-two__single "
                  style={{ height: "300px" }}
                >
                  <div className="features-two__single-top">
                    <img
                      src="assets/images/resources//logo-journal/logo_mpc.png"
                      style={{ margin: "auto" }}
                      alt=""
                    />
                  </div>
                  <div className="features-two__single-title">
                    <h3 style={{ textAlign: "center" }}>
                      <a href="https://prosiding.ff.unmul.ac.id">
                        Proc. Mul. Pharm. Conf.
                      </a>
                    </h3>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-3 col-lg-6 col-md-6  wow fadeInLeft" data-wow-delay="0ms"
                            data-wow-duration="1500ms">
                            <div className=" flex items-center justify-center flex-col features-two__single "style={{ height: "250px" }}>
                                <div className="features-two__single-top">
                                <img src="assets/images/resources/s1-farmasi.svg" style={{ margin: "auto" }} alt="" />
                                
                                </div>
                                <div className="features-two__single-title">
                                    <h3 style={{ textAlign: "center" }}><a href="https://ff.unmul.ac.id/">Jurnal Dedikasi Mulawarman</a></h3>
                                
                                </div>


                            </div>
                        </div> */}
            </div>
          </div>
        </section>

        <section class="blog-one">
          <div
            class="blog-one__bg wow slideInDown"
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          ></div>
          <div class="container ">
            <div class="sec-title text-center">
              <h2 className="sec-title__title">Fasilitas Memadai</h2>
              <p style={{ maxWidth: "800px", margin: "1rem auto" }}>
                Fakultas Farmasi Universitas Mulawarman selain dilengkapi dengan
                laboratorium modern dan fasilitas unggulan yang mendukung
                kegiatan praktikum, penelitian, serta pengembangan ilmu farmasi.
                Kami berkomitmen memberikan lingkungan belajar terbaik untuk
                mencetak lulusan yang kompeten dan siap bersaing secara global.
              </p>
            </div>

            <div class="row" style={{ margin: "auto" }}>
              <div
                class="col-xl-4 col-lg-6 wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1000ms"
              >
                <div class="services-one__single">
                  <div
                    class="services-one__single-img"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <div class="services-one__single-img-inner">
                      <img
                        style={{ height: "200px", objectFit: "cover" }}
                        src="assets/images/resources/ruang-teater.jpeg"
                        alt=""
                      />
                    </div>
                    <div class="services-one__single-img-icon">
                      <span class="icon-agriculture"></span>
                    </div>
                  </div>
                  <div
                    class="services-one__single-content text-center"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <h3>
                      <a href="/">Ruang Theater</a>
                    </h3>
                  </div>
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-6 wow fadeInLeft"
                data-wow-delay="100ms"
                data-wow-duration="1000ms"
              >
                <div class="services-one__single">
                  <div
                    class="services-one__single-img"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <div class="services-one__single-img-inner">
                      <img
                        style={{ height: "200px", objectFit: "cover" }}
                        src="assets/images/resources/perpustakaan.jpeg"
                        alt=""
                      />
                    </div>
                    <div class="services-one__single-img-icon">
                      <span class="icon-harvest"></span>
                    </div>
                  </div>
                  <div
                    class="services-one__single-content text-center"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <h3>
                      <a href="/">Perpustakaan</a>
                    </h3>
                  </div>
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-6 wow fadeInRight"
                data-wow-delay="0ms"
                data-wow-duration="1000ms"
              >
                <div class="services-one__single">
                  <div
                    class="services-one__single-img"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <div class="services-one__single-img-inner">
                      <img
                        style={{ height: "200px", objectFit: "cover" }}
                        src="assets/images/resources/ruang-kuliah-farmasi.jpeg"
                        alt=""
                      />
                    </div>
                    <div class="services-one__single-img-icon">
                      <span class="icon-growth"></span>
                    </div>
                  </div>
                  <div
                    class="services-one__single-content text-center"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <h3>
                      <a href="/">Ruang Kuliah</a>
                    </h3>
                  </div>
                </div>
              </div>

              <div
                className="col-0 col-lg-2 col-xl-2"
                id="temp_colum_in_facility_section_1"
              ></div>

              <div
                class="col-xl-4 col-lg-6 wow fadeInRight"
                data-wow-delay="100ms"
                data-wow-duration="1000ms"
              >
                <div class="services-one__single">
                  <div
                    class="services-one__single-img"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <div class="services-one__single-img-inner">
                      <img
                        style={{ height: "200px", objectFit: "cover" }}
                        src="assets/images/resources/smart-classroom.jpeg"
                        alt=""
                      />
                    </div>
                    <div class="services-one__single-img-icon">
                      <span class="icon-dairy-products"></span>
                    </div>
                  </div>
                  <div
                    class="services-one__single-content text-center"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <h3>
                      <a href="/">Smart Classroom</a>
                    </h3>
                  </div>
                </div>
              </div>

              <div
                className="col-0 col-lg-3 col-xl-2"
                id="temp_colum_in_facility_section_2"
              ></div>

              <div
                class="col-xl-4 col-lg-6 wow fadeInRight"
                data-wow-delay="100ms"
                data-wow-duration="1000ms"
              >
                <div class="services-one__single">
                  <div
                    class="services-one__single-img"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <div class="services-one__single-img-inner">
                      <img
                        style={{ height: "200px", objectFit: "cover" }}
                        src="assets/images/resources/mushola.jpeg"
                        alt=""
                      />
                    </div>
                    <div class="services-one__single-img-icon">
                      <span class="icon-dairy-products"></span>
                    </div>
                  </div>
                  <div
                    class="services-one__single-content text-center"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <h3>
                      <a href="/">Mushola</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-one">
          <div
            className=""
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          ></div>

          <div className="container">
            <div className="sec-title text-center">
              <span className="sec-title__tagline">Acara Terbaru</span>
              <h2 className="sec-title__title">Acara Terkini</h2>
            </div>
            {loading ? (
              <div className="mb-6 mx-auto flex flex-col md:flex-row justify-center items-center gap-6 max-w-screen-xl">
                {[1, 2, 3].map((item, index) => (
                  <div
                    key={index}
                    role="status"
                    class="flex-1 max-w-lg p-4 border border-gray-200 rounded-sm shadow-sm animate-pulse md:p-6"
                  >
                    <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded-sm ">
                      <svg
                        class="w-10 h-10 text-gray-200 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 16 20"
                      >
                        <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                        <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                      </svg>
                    </div>
                    <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                    <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full "></div>
                    <div class="flex items-center mt-4">
                      <div>
                        <div class="h-2.5 bg-gray-200 rounded-full  w-32 mb-2"></div>
                        <div class="w-48 h-2 bg-gray-200 rounded-full "></div>
                      </div>
                    </div>
                    <span class="sr-only">Loading...</span>
                  </div>
                ))}
              </div>
            ) : (
              <section class="blog-one blog-one--news p-6">
                <div
                  class="blog-one__bg wow slideInDown"
                  data-wow-delay="100ms"
                  data-wow-duration="2500ms"
                ></div>
                <div class="container">
                  <div class="row">
                    {programData.map((item: any) => (
                      <div
                        className="col-xl-4 col-lg-4  wow fadeInLeft"
                        data-wow-delay="0ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="blog-one__single">
                          <div className="blog-one__single-img">
                            {imagePathsProgram[item.id] ? (
                              <img
                                src={imagePathsProgram[item.id]}
                                className="h-[300px] object-cover object-center"
                                alt={item.title_idn || "Gambar berita"}
                              />
                            ) : (
                              <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded-sm ">
                                <svg
                                  class="w-10 h-10 text-gray-200 "
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 16 20"
                                >
                                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                                </svg>
                              </div>
                            )}
                            <div className="date-box">
                              <span>{formattedDate(item.created_at)}</span>
                            </div>
                          </div>

                          <div className="blog-one__single-content min-h-[150px] max-h-[300px]">
                            <h2 className="text-lg uppercase">
                              <Link to={`/acara/detail/${item.slug}`}>
                                {item.title_idn
                                  .split(" ")
                                  .slice(0, 5)
                                  .join(" ") +
                                  (item.title_idn.split(" ").length > 5
                                    ? "..."
                                    : "")}
                              </Link>
                            </h2>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to="/acara" className="thm-btn comment-form__btn">
                Lihat Semua
              </Link>
            </div>
          </div>
        </section>

        <section className="blog-one">
          <div
            className="blog-one__bg wow slideInDown"
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          ></div>

          <div className="container">
            <div className="sec-title text-center">
              <span className="sec-title__tagline">Berita & Artikel</span>
              <h2 className="sec-title__title">Unggahan Terbaru</h2>
            </div>
            <div className="row">
              {loading ? (
                <div className="mb-6 mx-auto flex flex-col md:flex-row justify-center items-center gap-6 max-w-screen-xl">
                  {[1, 2, 3].map((item, index) => (
                    <div
                      key={index}
                      role="status"
                      class="flex-1 max-w-lg p-4 border border-gray-200 rounded-sm shadow-sm animate-pulse md:p-6"
                    >
                      <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded-sm ">
                        <svg
                          class="w-10 h-10 text-gray-200 "
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 16 20"
                        >
                          <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                          <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                        </svg>
                      </div>
                      <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                      <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                      <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                      <div class="h-2 bg-gray-200 rounded-full "></div>
                      <div class="flex items-center mt-4">
                        <div>
                          <div class="h-2.5 bg-gray-200 rounded-full  w-32 mb-2"></div>
                          <div class="w-48 h-2 bg-gray-200 rounded-full "></div>
                        </div>
                      </div>
                      <span class="sr-only">Loading...</span>
                    </div>
                  ))}
                </div>
              ) : (
                <section class="blog-one blog-one--news p-6 bg-none">
                  <div
                    class="blog-one__bg wow slideInDown"
                    data-wow-delay="100ms"
                    data-wow-duration="2500ms"
                  ></div>
                  <div class="container">
                    <div class="row">
                      {newsData.map((item: any) => (
                        <div
                          className="col-xl-4 col-lg-4  wow fadeInLeft"
                          data-wow-delay="0ms"
                          data-wow-duration="1500ms"
                        >
                          <div className="blog-one__single">
                            <div className="blog-one__single-img">
                              {imagePathsNews[item.id] ? (
                                <img
                                  src={imagePathsNews[item.id]}
                                  className="h-[300px] object-cover object-center"
                                  alt={item.title_idn || "Gambar berita"}
                                />
                              ) : (
                                <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded-sm ">
                                  <svg
                                    class="w-10 h-10 text-gray-200 "
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 16 20"
                                  >
                                    <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                                  </svg>
                                </div>
                              )}
                              <div className="date-box">
                                <span>{formattedDate(item.created_at)}</span>
                              </div>
                            </div>

                            <div className="blog-one__single-content min-h-[150px] max-h-[300px]">
                              <h2 className="text-lg uppercase">
                                <Link to={`/berita/detail/${item.slug}`}>
                                  {item.title_idn
                                    .split(" ")
                                    .slice(0, 5)
                                    .join(" ") +
                                    (item.title_idn.split(" ").length > 5
                                      ? "..."
                                      : "")}
                                </Link>
                              </h2>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to="/berita" className="thm-btn comment-form__btn">
                Lihat Semua
              </Link>
            </div>
          </div>
        </section>

        <section class="projects-details">
          <div class="container">
            <h2 className="sec-title__title text-center mb-4">
              Video Fakultas Farmasi
            </h2>

            <div className="grid h-[600px] grid-cols-1 gap-4">
              <Carousel slide={false}>
                <div className="projects-details__video mt-4">
                  <iframe
                    width="100%"
                    height="600"
                    src="https://www.youtube.com/embed/pbxLu14_xwo?si=bNpLTY-NSyv-MZUW"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="projects-details__video mt-4">
                  <iframe
                    width="100%"
                    height="600"
                    src="https://www.youtube.com/embed/6dA3PqB5zTw?si=R2IEGAnM_7g6co6f"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </Carousel>
            </div>
          </div>
        </section>

        <section className="features-two">
          <div className="container">
            <div className="d-flex justify-center align-items-center mb-6">
              <h2 class="sec-title__title mx-auto">Survey Kepuasan</h2>
            </div>

            <div className="row">
              <div className=" col-12 text-center">
                <p>
                  Survei ini bertujuan untuk menilai tingkat kepuasan Mahasiswa,
                  Dosen, Tenaga Kependidikan, dan Mitra terhadap berbagai aspek
                  yang berkaitan dengan layanan, fasilitas, serta mutu
                  pendidikan di fakultas farmasi universitas mulawarman. Masukan
                  yang diperoleh dari survei ini juga akan menjadi dasar dalam
                  perencanaan strategi pengembangan guna meningkatkan pengalaman
                  akademik dan profesional bagi seluruh civitas akademika serta
                  mitra fakultas.
                </p>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Link
                  to="/survey-kepuasan-civitas"
                  style={{ marginTop: "2rem" }}
                  className="thm-btn comment-form__btn"
                >
                  Isi Survey <span class="icon-right-arrow-2"></span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}
