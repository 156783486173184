import axios from "axios";
import STORE_URL from "../utils/EncodeUrl.ts";

 const fetchImage = async (filename) => {
    try {
      const path = `web/file/event/${filename}`;
      const fullUrl = STORE_URL(`path=${path}`);

      const response = await axios.get(fullUrl, { responseType: "blob" });
      
      const imageUrl = URL.createObjectURL(response.data);
      return imageUrl;
    } catch (error) {
      console.error("Terjadi error saat mengambil gambar:", error);
      return null;
    }
  };

const fetchNewsData = async (setLoading, setError, currentPage, itemsPerPage, setProgramData, setImagePaths) => {
  try {
    setLoading(true);

    // URL untuk mendapatkan data berita
    const url = STORE_URL(
      `path=web/event?page=${currentPage}&limit=${itemsPerPage}&order=created_at desc`
    );

    const response = await axios.get(url);
    setProgramData(response.data.data);

    // Fetch image untuk setiap item berita
    const imageUrls = {};
    for (let item of response.data.data) {
      const imageUrl = await fetchImage(item.id);
      imageUrls[item.id] = imageUrl; // Menyimpan URL gambar per item
    }
    setImagePaths(imageUrls);
  } catch (err) {
    console.error("Terjadi error:", err);
    setError("Gagal mengambil data berita");
  } finally {
    setLoading(false);
  }
};

export default fetchNewsData;