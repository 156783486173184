import React, { } from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';

export default function Gallery() {
 

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Galeri"
          breadcrumb={[
            { label: 'Beranda', link: '/' },
            { label: 'Galeri', link: '/galeri' },
          ]}
        ></PageHeader>


        <section class="blog-one blog-one--news">
            <div class="blog-one__bg wow slideInDown" data-wow-delay="100ms" data-wow-duration="2500ms"></div>
            <div class="container">
                <div class="row">
                </div>
            </div>
        </section>

  
      </MainLayout>
    </>
  );
}
