const formattedDate = (originalDate: string): string => {
  const date = new Date(originalDate);

  return date
    .toLocaleDateString("id-ID", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
    .toLowerCase();
};

export default formattedDate;
