import React from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';

export default function TheaterRoom() {
  return (
   <>
     <MainLayout children={undefined}>

        <PageHeader title="Ruang Teater" breadcrumb={[{ label: "Beranda", link: "/" }, { label: "Ruang Teater", link: "/ruang-teater" }]}></PageHeader>


        <section class="projects-details">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="projects-details__wrapper">
                            <div class="projects-details__img">
                                <img className="h-56 sm:h-64 xl:h-80 2xl:h-96 object-cover object-center" src="/assets/images/resources/ruang-teater.jpeg" style={{ width: "100%" } } alt="" />
                            </div>


                            <div className="projects-details__text-box1">
                                <p className="mt-4 text-justify">Ruang Teater Farmasi Universitas Mulawarman terletak di Gedung Farmasi-C lantai 1. Ruang Teater ini dilengkapi dengan pendingin ruangan, papan tulis, layar proyektor, mic dan speaker. Ruangan ini digunakan untuk proses pembelajaran, selain itu digunakan juga untuk kegiatan seminar, rapat, dll.
                                </p>
                              </div>

                     
                     
                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
       



        </MainLayout>
   </>
  );
}