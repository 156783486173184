import React, { useState, useEffect } from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';
import axios from 'axios';

interface DevotionData {
  id: number;
  title: string;
  title_en: string;
  slug: string;
}

export default function Devotion() {
 

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Pengabdian"
          breadcrumb={[
            { label: 'Beranda', link: '/' },
            { label: 'Pengabdian', link: '/pengabdian' },
          ]}
        ></PageHeader>

        <section className="blog-one blog-one--news">
          <div
            className="blog-one__bg wow slideInDown"
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          ></div>
          <div className="container">
           
          </div>
        </section>
      </MainLayout>
    </>
  );
}
