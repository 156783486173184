import React from "react";
import MainLayout from "../../layout/MainLayout.tsx";
import PageHeader from "../../components/PageHeader.tsx";
import ServiceForm from "./components/ServiceForm.tsx";

export default function SholarshipStep() {
  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Surat Cuti"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: "layanan Mahasiswa", link: "/content/layanan-mahasiswa" },
            { label: "Surat Cuti", link: "/content/surat-cuti" },
          ]}
        ></PageHeader>

        <section class="projects-details">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="projects-details__wrapper">
                  <div class="projects-details__img">
                    <img
                      src="/assets/images/resources/perpustakaan.jpeg"
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ServiceForm type="surat-cuti"></ServiceForm>
      </MainLayout>
    </>
  );
}
