import React, { useState, useEffect } from 'react';
import MainLayout from '../../layout/MainLayout.tsx';
import PageHeader from '../../components/PageHeader.tsx';
import { Link } from 'react-router-dom';

export default function RegulationInfo() {
 

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Informasi Regulasi"
          breadcrumb={[
            { label: 'Beranda', link: '/' },
            { label: 'Informasi Regulasi', link: '/ppid/informasi-regulasi' },
          ]}
        ></PageHeader>


        <section class="cart-table">
                <div class="container" style={{ marginBottom: "4rem" }}>
                  <table>
                
                    <tbody>
                      <tr>
                        <td data-label="Item">
                          <div class="cart-table__item">
                            <h3 class="cart-table__item__title">1</h3>
                          
                          </div>
                        </td>
                        <td data-label="Price">	Standar Mutu Pembelajaran</td>
                       
                        <td data-label="Total">
                          <Link target="_blank" to="https://ff.unmul.ac.id/file/image/ppid/ZDM0Njk2NThlODcwYWQ3MDZmNDZjZDVkN2Q5OTE5ZmQyZTIxMzg4MWZlYzY2NTY1ZTg5MjVkZDIxZjY5NDA0OWI5ZmQxZTYzZjBlYzNkYWIwNDhjNWRiYjlkYzJjY2ZmN2QxMTM1ZTljMjU5MTg4MTFjNjhjMTI4MzA5N2U4ZGZ1T1RIWktTbHp0d2ZjaC82dURtRXRpNTQ=" style={{ textDecoration: "underline" }}>Unduh</Link>
                        </td>
                       
                      </tr>

                      <tr>
                        <td data-label="Item">
                          <div class="cart-table__item">
                            <h3 class="cart-table__item__title">2</h3>
                          
                          </div>
                        </td>
                        <td data-label="Price">	Standar Mutu Penelitian</td>
                       
                        <td data-label="Total">
                          <Link target="_blank" to="https://ff.unmul.ac.id/file/image/ppid/ZGQyOTBmNTc2MTllZjg0MjUwYmQ4N2MyMWY3NTkyMTEwNjVjZDM4ZGU5ZTg0YjJiYTkwNWE4OGE4M2NjYzhjZmE0ODhkNGE3YTQ5MTRlZDdhN2NhYzA1NGYyMmNjN2IzMmQ5N2ZjNTRmNzE0ZDc2Y2UyNDJjNGM2NDI4ZTM2ZGZxL1g1dEp1M1VqS0pnYnh4T08yYWVkOEk=" style={{ textDecoration: "underline" }}>Unduh</Link>
                        </td>
                       
                      </tr>

                      
                      <tr>
                        <td data-label="Item">
                          <div class="cart-table__item">
                            <h3 class="cart-table__item__title">3</h3>
                          
                          </div>
                        </td>
                        <td data-label="Price">	Standar Mutu Pengabdian Masyarakat</td>
                       
                        <td data-label="Total">
                          <Link target="_blank" to="https://ff.unmul.ac.id/file/image/ppid/MWFjOGUzYWUxNGIwOWE0YmFjN2VhZTQ0Y2JiZTIyMTA5NGIyZjQ5MGNlZGUzNDFhNjQ1ODE2ZDA5ZjM0YmFkY2JlMzZlZTAxZDk3YmM5NmYxMDFhYThkNzNjZTVkMTllNTk5MzhhYmQxNjEwNTM5MTdiNjUwMWU2OTJhNTk5ZmZyT0xIWHJObURPSkVoMmJxRkE0WVRRVzk=" style={{ textDecoration: "underline" }}>Unduh</Link>
                        </td>
                       
                      </tr>

                      <tr>
                        <td data-label="Item">
                          <div class="cart-table__item">
                            <h3 class="cart-table__item__title">4</h3>
                          
                          </div>
                        </td>
                        <td data-label="Price">	Standar Mutu Tambahan</td>
                       
                        <td data-label="Total">
                          <Link target="_blank" to="https://ff.unmul.ac.id/file/image/ppid/YWM3ZWVjNTRiYzAyNThhODgyNWQyYmVlYmU2MTQwYTEyOWI5NjQwYTE0MTYwM2M5NjJlOWM2YWY5ZjNjNjZhN2YxNjAzNThkZGU4MjI1NTg1OTVhYWVhMjk3ZDU1MGE5MDc5Yjk5YjgzMDk2ODM2NDFiYjZlM2RjODM0N2JkYTBpWjJTQlQ0QVNJSWtIK21WN28wOWwyUFk=" style={{ textDecoration: "underline" }}>Unduh</Link>
                        </td>
                       
                      </tr>

                    </tbody>
                  </table>
                </div>
		        </section>


    
  
      </MainLayout>
    </>
  );
}
