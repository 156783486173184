import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import HomePage from "./resource/pages/Home.tsx";
import ValuePage from "./resource/pages/Value.tsx";
import LecturerPage from "./resource/pages/Lecturer.tsx";
import HistoryPage from "./resource/pages/History.tsx";
import StaffPage from "./resource/pages/Staff.tsx";
import OrgantizationalStructurePage from "./resource/pages/OrganizationalStructure.tsx";
import FaculityLeaderPage from "./resource/pages/FaculityLeader.tsx";
import IntegrityZone from "./resource/pages/IntegrityZone.tsx";
import ResearchPage from "./resource/pages/Research.tsx";
import BooksPage from "./resource/pages/Books.tsx";
import DevotionPage from "./resource/pages/Devotion.tsx";
import AlumniPage from "./resource/pages/Alumni.tsx";
import AcademicCalendarPage from "./resource/pages/AcademicCalendar.tsx";
import DetailAcademicCalendarPage from "./resource/pages/DetailAcademicCalendar.tsx";
import KEPKInformationPage from "./resource/pages/KEPKInformation.tsx";
import AboutFacultyQualityPage from "./resource/pages/AboutFacultyQuality.tsx";
import InternalAuditQualityPage from "./resource/pages/InternalAuditQuality.tsx";
import AccreditationCriteriaPage from "./resource/pages/AccreditationCriteria.tsx";
import TheaterRoomPage from "./resource/pages/TheaterRoom.tsx";
import MosquePage from "./resource/pages/Mosque.tsx";
import SmartClassroomPage from "./resource/pages/SmartClassroom.tsx";
import LibraryPage from "./resource/pages/Library.tsx";
import FarmacyStudyRoomPage from "./resource/pages/FarmacyStudyRoom.tsx";

import NewsPage from "./resource/pages/News/News.tsx";
import DetailNewsPage from "./resource/pages/News/Detail.tsx";

import AnnouncementPage from "./resource/pages/Announcement/Announcement.tsx";
import DetailAnnouncementPage from "./resource/pages/Announcement/Detail.tsx";

import AgendaPage from "./resource/pages/Agenda/Agenda.tsx";
import GalleryPage from "./resource/pages/Gallery.tsx";

import ProgramPage from "./resource/pages/Program/Program.tsx";
import DetailProgramPage from "./resource/pages/Program/Detail.tsx";

import LibraryInfoPage from "./resource/pages/Library/Library.tsx";

import AvailableInfoPage from "./resource/pages/PPID/AvailableInfo.tsx";
import ExcludedInfoPage from "./resource/pages/PPID/ExcludedInfo.tsx";
import NecessarilyInfoPage from "./resource/pages/PPID/NecessarilyInfo.tsx";
import ObligatoryInfoPage from "./resource/pages/PPID/ObligatoryInfo.tsx";
import RegulationInfoPage from "./resource/pages/PPID/RegulationInfo.tsx";

import Laboratorium from "./resource/pages/Laboratorium/Laboratorium.tsx";

import SchoolarshipPage from "./resource/pages/Scholarship.tsx";
import CounselingPage from "./resource/pages/Counseling.tsx";
import StudentServicePage from "./resource/pages/StudentService.tsx";

import ActiveStudyStepPage from "./resource/pages/StudentServices/ActiveStudyStep.tsx";
import StudyLetterStepPage from "./resource/pages/StudentServices/StudyLetterStep.tsx";
import TranscriptStepPage from "./resource/pages/StudentServices/TranscriptStep.tsx";
import SKLClinicStepPage from "./resource/pages/StudentServices/SKLClinicStep.tsx";
import ScholarshipStepPage from "./resource/pages/StudentServices/ScholarshipStep.tsx";
import SKLGeneralStepPage from "./resource/pages/StudentServices/SKLGeneralStep.tsx";
import DispenseLetterStepPage from "./resource/pages/StudentServices/DispenseLetterStep.tsx";
import AbsenceLetterStepPage from "./resource/pages/StudentServices/AbsenceLetterStep.tsx";
import TheaterRoomStepPage from "./resource/pages/StudentServices/TheaterRoomStep.tsx";
import BorrowRoomStepPage from "./resource/pages/StudentServices/BorrowRoomStep.tsx";

import DiplomaPage from "./resource/pages/StudyProgram/Diploma/Index.tsx";
import DetailDiplomaPage from "./resource/pages/StudyProgram/Diploma/Detail.tsx";

import PharmacistPage from "./resource/pages/StudyProgram/Pharmacist/Index.tsx";
import DetailPharmacistPage from "./resource/pages/StudyProgram/Pharmacist/Detail.tsx";

import BachelorPharmacyPage from "./resource/pages/StudyProgram/BachelorPharmacy/Index.tsx";
import DetailBachelorPharmacyPage from "./resource/pages/StudyProgram/BachelorPharmacy/Detail.tsx";

import BachelorClinicalPage from "./resource/pages/StudyProgram/BachelorClinical/Index.tsx";
import DetailBachelorClinicalPage from "./resource/pages/StudyProgram/BachelorClinical/Detail.tsx";

import StudyCostPage from "./resource/pages/StudyCost.tsx";
import SatisfactionSurveyPage from "./resource/pages/SatisfactionSurvey.tsx";

import AccreditationPage from "./resource/pages/Accreditation.tsx";
import AccreditationCriteriaDetailPage from "./resource/pages/AccreditationCriteriaDetail.tsx";

import PartnerPage from "./resource/pages/Partner.tsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/visi-misi" element={<ValuePage />} />
        <Route path="/dosen" element={<LecturerPage />} />
        <Route path="/sejarah" element={<HistoryPage />} />
        <Route path="/tendik" element={<StaffPage />} />
        <Route
          path="/struktur-organisasi"
          element={<OrgantizationalStructurePage />}
        />
        <Route path="/pimpinan-fakultas" element={<FaculityLeaderPage />} />
        <Route path="/zona-integritas" element={<IntegrityZone />} />
        <Route path="/penelitian" element={<ResearchPage />} />
        <Route path="/buku-dan-riset" element={<BooksPage />} />
        <Route path="/pengabdian" element={<DevotionPage />} />
        <Route path="/alumni" element={<AlumniPage />} />
        <Route path="/kalender-akademik" element={<AcademicCalendarPage />} />
        <Route
          path="/kalender-akademik/:slug"
          element={<DetailAcademicCalendarPage />}
        />
        <Route path="/biaya-pendidikan" element={<StudyCostPage />} />
        <Route path="/kepk" element={<KEPKInformationPage />} />
        <Route
          path="/tentang-penjamin-mutu-fakultas"
          element={<AboutFacultyQualityPage />}
        />
        <Route
          path="/laporan-audit-mutu-internal"
          element={<InternalAuditQualityPage />}
        />
        <Route
          path="/kriteria-data-akreditasi"
          element={<AccreditationCriteriaPage />}
        />
        <Route path="/content/ruang-teater" element={<TheaterRoomPage />} />
        <Route path="/content/mushola" element={<MosquePage />} />
        <Route
          path="/content/smart-classroom"
          element={<SmartClassroomPage />}
        />
        <Route path="/content/perpustakaan" element={<LibraryPage />} />
        <Route
          path="/content/ruang-kuliah-farmasi"
          element={<FarmacyStudyRoomPage />}
        />
        <Route path="/berita" element={<NewsPage />} />
        <Route path="/berita/detail/:slug" element={<DetailNewsPage />} />
        <Route path="/pengumuman" element={<AnnouncementPage />} />
        <Route
          path="/pengumuman/detail/:slug"
          element={<DetailAnnouncementPage />}
        />
        <Route path="/agenda" element={<AgendaPage />} />
        <Route path="/galeri" element={<GalleryPage />} />
        <Route path="/acara" element={<ProgramPage />} />
        <Route path="/acara/detail/:slug" element={<DetailProgramPage />} />
        <Route path="/perpustakaan" element={<LibraryInfoPage />} />
        <Route
          path="/ppid/informasi-wajib-berkala"
          element={<ObligatoryInfoPage />}
        />
        <Route
          path="/ppid/informasi-tersedia-setiap-saat"
          element={<AvailableInfoPage />}
        />
        <Route
          path="/ppid/informasi-yang-dikecualikan"
          element={<ExcludedInfoPage />}
        />
        <Route
          path="/ppid/informasi-diumumkan-serta-merta"
          element={<NecessarilyInfoPage />}
        />
        <Route path="/informasi-regulasi" element={<RegulationInfoPage />} />
        <Route
          path="/survey-kepuasan-civitas"
          element={<SatisfactionSurveyPage />}
        />
        <Route path="/content/beasiswa" element={<SchoolarshipPage />} />
        <Route
          path="/content/bimbingan-konseling"
          element={<CounselingPage />}
        />
        <Route
          path="/content/layanan-mahasiswa"
          element={<StudentServicePage />}
        />
        <Route
          path="/content/surat-rekomendasi-beasiswa"
          element={<ScholarshipStepPage />}
        />
        <Route
          path="/content/skl-dan-sertifikat-ukom-d3-dan-s1-farmasi-umum"
          element={<SKLGeneralStepPage />}
        />
        <Route
          path="/content/surat-aktif-kuliah"
          element={<ActiveStudyStepPage />}
        />
        <Route path="/mitra-kerjasama" element={<PartnerPage />} />
        <Route
          path="/content/skl-dan-sertifikat-ukom-profesi-apoteker-dan-s1-farmasi-klinik"
          element={<SKLClinicStepPage />}
        />
        <Route
          path="/content/transkrip-sementara-khs-surat-keterangan-alumni-fakultas-farmasi-unmul-form-bebas-administrasi"
          element={<TranscriptStepPage />}
        />
        <Route
          path="/content/surat-keterangan-sedang-menyelesaikan-tugas-akhir"
          element={<StudyLetterStepPage />}
        />
        <Route
          path="/content/surat-dispensasi"
          element={<DispenseLetterStepPage />}
        />
        <Route path="/content/surat-cuti" element={<AbsenceLetterStepPage />} />
        <Route
          path="/content/peminjaman-ruangan-untuk-sidang"
          element={<BorrowRoomStepPage />}
        />
        <Route
          path="/content/peminjaman-teater-farmasi-unmul-untuk-kegiatan"
          element={<TheaterRoomStepPage />}
        />

        {/* new prodi */}
        <Route path="/prodi/diploma" element={<DiplomaPage />} />
        <Route path="/prodi/diploma/:slug" element={<DetailDiplomaPage />} />

        <Route path="/prodi/s1-farmasi" element={<BachelorPharmacyPage />} />
        <Route
          path="/prodi/s1-farmasi/:slug"
          element={<DetailBachelorPharmacyPage />}
        />

        <Route path="/prodi/profesi-apoteker" element={<PharmacistPage />} />
        <Route
          path="/prodi/profesi-apoteker/:slug"
          element={<DetailPharmacistPage />}
        />

        <Route
          path="/prodi/s1-farmasi-klinis"
          element={<BachelorClinicalPage />}
        />
        <Route
          path="/prodi/s1-farmasi-klinis/:slug"
          element={<DetailBachelorClinicalPage />}
        />

        <Route
          path="/content/laboratorium-farmakognosi--kimia-medisinal"
          element={
            <Laboratorium
              id={"1"}
              title={"Laboratorium Farmakognosi & Kimia Medisinal"}
              url={"content/laboratorium-farmakognosi--kimia-medisinal"}
            />
          }
        />
        <Route
          path="/content/laboratorium-farmasetika"
          element={
            <Laboratorium
              id={"2"}
              title={"Laboratorium Farmasetika"}
              url={"content/laboratorium-farmasetika"}
            />
          }
        />
        <Route
          path="/content/laboratorium-farmakologi"
          element={
            <Laboratorium
              id={"3"}
              title={"Laboratorium Farmakologi"}
              url={"content/laboratorium-farmakologi"}
            />
          }
        />
        <Route
          path="/content/laboratorium-farmasi-klinik-dan-komunitas"
          element={
            <Laboratorium
              id={"4"}
              title={"Laboratorium Farmasi Klinik dan Komunitas"}
              url={"content/laboratorium-farmasi-klinik-dan-komunitas"}
            />
          }
        />
        <Route
          path="/content/laboratorium-umum-pendidikan-penelitian-kefarmasian"
          element={
            <Laboratorium
              id={"5"}
              title={"Laboratorium Umum Pendidikan Penelitian Kefarmasian"}
              url={
                "content/laboratorium-umum-pendidikan-penelitian-kefarmasian"
              }
            />
          }
        />
        <Route
          path="/content/laboratorium-praktek-kerja-kefarmasian"
          element={
            <Laboratorium
              id={"6"}
              title={"Laboratorium Praktek Kerja Kefarmasian"}
              url={"content/laboratorium-praktek-kerja-kefarmasian"}
            />
          }
        />
        <Route path="/akreditasi" element={<AccreditationPage />} />
        <Route
          path="/content/kriteria/:id"
          element={<AccreditationCriteriaDetailPage />}
        />
      </Routes>
    </Router>
  );
}

export default App;
