import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import MainLayout from "./../../layout/MainLayout.tsx";
import PageHeader from "./../../components/PageHeader.tsx";

import announcementData from "../../data/announcements.json";

export default function Detail() {
  const { slug } = useParams<{ slug: string }>();
  const [announcementItem, setannouncementItem] = useState<any>(null);

  const mainImage = () => {
    if (announcementItem?.image.length === 0) {
      return (
        <img
          src="/assets/images/resources/no-picture.jpg"
          class="max-h-[500px] object-cover object-center"
          alt=""
        />
      );
    }

    if (announcementItem?.image.length > 1) {
      return <img src={announcementItem?.image[0]} alt="" />;
    } else {
      return <img src={announcementItem?.image[0]} alt="" />;
    }
  };

  useEffect(() => {
    const item = announcementData.find((news) => news.slug === slug);
    setannouncementItem(item);
  }, [slug]);

  return (
    <MainLayout children={undefined}>
      <PageHeader
        title={announcementItem?.title}
        breadcrumb={[
          { label: "Beranda", link: "/" },
          { label: "Pengumuman", link: "/pengumuman" },
          { label: announcementItem?.title || "Detail", link: "#" },
        ]}
      />

      <section class="news-details">
        <div class="container">
          {announcementItem ? (
            <div class="row">
              <div class="col-xl-12 col-lg-11">
                <div class="news-details__left">
                  <div class="blog-one__single">
                    <div class="blog-one__single-img">{mainImage()}</div>

                    <div class="blog-one__single-content blog-details">
                      <ul class="meta-info">
                        <li>
                          <a href="/">
                            <i class="far fa-clock"></i>
                            {announcementItem?.date}
                          </a>
                        </li>
                      </ul>
                      <h2>{announcementItem?.title}</h2>

                      {announcementItem?.description && (
                        <div
                          className="text-justify"
                          dangerouslySetInnerHTML={{
                            __html: announcementItem.description,
                          }}
                        ></div>
                      )}
                    </div>
                  </div>

                  <div class="tag-social-link">
                    <div class="tag-social-link__tag">
                      <div class="title">
                        <h4>Tags</h4>
                      </div>
                      <div class="tag-social-link__tag-list">
                        <ul>
                          {announcementItem?.tags.map(
                            (tag: string, index: number) => (
                              <li>
                                <Link href="#">{tag}</Link>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Pengumuman tidak ditemukan.</p>
          )}
        </div>
      </section>
    </MainLayout>
  );
}
