import React from 'react';
import MainLayout from '../../layout/MainLayout.tsx';
import PageHeader from '../../components/PageHeader.tsx';
import ServiceForm from './components/ServiceForm.tsx';

export default function SholarshipStep() {
  return (
    <>
      <MainLayout children={undefined}>

        <PageHeader title="SKL dan Sertifikat S1 Farmasi Klinik" breadcrumb={[{ label: "Beranda", link: "/" }, { label: "layanan Mahasiswa", link: "/content/layanan-mahasiswa" }, { label: "SKL dan Sertifikat S1 Farmasi Klinik", link: "/content/skl-dan-sertifikat-s1-farmasi-klinik" }]}></PageHeader>


        <section class="projects-details">
          <div class="container">
            <div class="row">
              <h2 style={{ textAlign: "center", marginBottom: "2rem" }}>Alur Pembuatan SKL dan Sertifikat S1 Farmasi Klinik</h2>
              <div class="col-xl-12">
                <div class="projects-details__wrapper">
                  <div class="projects-details__img">
                    <img src="/assets/images/resources/skl-farmasi-klinik.webp" style={{ width: "100%" }} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <ServiceForm type="pembuatan-skl-sertifikat-s1-farmasi-klinik"></ServiceForm>
      </MainLayout>
    </>
  );
}
