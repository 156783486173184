import React from "react";
import MainLayout from "../../layout/MainLayout.tsx";
import PageHeader from "../../components/PageHeader.tsx";
import ServiceForm from "./components/ServiceForm.tsx";

export default function SholarshipStep() {
  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Peminjaman Ruangan untuk Sidang"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: "layanan Mahasiswa", link: "/content/layanan-mahasiswa" },
            {
              label: "Peminjaman Ruangan untuk Sidang",
              link: "/content/peminjaman-ruangan-untuk-sidang",
            },
          ]}
        ></PageHeader>

        <section class="projects-details">
          <div class="container">
            <div class="row">
              <h2 style={{ textAlign: "center", marginBottom: "2rem" }}>
                Alur Peminjaman Ruagan untuk Sidang
              </h2>
              <div class="col-xl-12">
                <div class="projects-details__wrapper">
                  <div class="projects-details__img">
                    <img
                      src="/assets/images/resources/peminjaman-ruangan-untuk-sidang.webp"
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ServiceForm type="peminjaman-ruangan"></ServiceForm>
      </MainLayout>
    </>
  );
}
