import React from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';
import { Carousel } from "flowbite-react";

export default function SmartClassroom() {
  return (
   <>
     <MainLayout children={undefined}>

        <PageHeader title="Smart Classroom" breadcrumb={[{ label: "Beranda", link: "/" }, { label: "Smart Classroom", link: "/smart-classroom" }]}></PageHeader>


        <section class="projects-details">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="projects-details__wrapper">
                            <div class="projects-details__img w-[100%]" >

                            <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
                                <Carousel>
                                  
                                    {
                                        ['/assets/images/resources/smart-classroom-1.jpg', '/assets/images/resources/smart-classroom-2.jpg', '/assets/images/resources/smart-classroom-3.jpg', '/assets/images/resources/smart-classroom-4.jpg'].map((image, index) => (
                                                <img
                                                    src={image}
                                                    alt="Background"
                                                    className="object-cover object-center h-full w-full"
                                                />
                                        
                                        ))
                                        }
                                </Carousel>
                                </div>
                            </div>


                            <div className="projects-details__text-box1">
                                <p className="mt-4 text-justify">Smart Classroom Farmasi Universitas Mulawarman terletak di Gedung Farmasi-A dan Gedung Farmasi-C. Ruangan ini dilengkapi dengan ruangan pendingin, kursi dan meja, papan tulis, TV, Smart Board, dan komputer. Ruangan ini digunakan untuk kegiatan perkuliahan.</p>
                              </div> 

                     
                     
                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
       



        </MainLayout>
   </>
  );
}