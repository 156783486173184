import React, { useEffect, useState } from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";
import axios from "axios";
import STORE_URL from "../utils/EncodeUrl.ts";

export default function IntegrityZone() {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async (filename) => {
      try {
        const path = `web/file/content/${filename}`;
        const fullUrl = STORE_URL(`path=${path}`);

        const response = await axios.get(fullUrl, { responseType: "blob" });

        const imageUrl = URL.createObjectURL(response.data);
        return imageUrl;
      } catch (error) {
        console.error("Terjadi error saat mengambil gambar:", error);
        return null;
      }
    };

    const fetchData = async () => {
      try {
        setLoading(true);

        const url = STORE_URL(`path=web/content/find/zona-integritas`);

        axios
          .get(url)
          .then(async (response) => {
            setData(response.data.data);

            const imageUrl = await fetchImage(response.data.data.id);
            setImage(imageUrl);
          })
          .catch((error) => {
            console.error("Terjadi error:", error);
          });
      } catch (err: any) {
        setError("Gagal mengambil data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Zona Integritas"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: "Zona Integritas", link: "/zona-integritas" },
          ]}
        ></PageHeader>

        <section class="projects-details">
          <div class="container">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : data ? (
              <div class="row">
                <div class="col-xl-12">
                  <div class="projects-details__wrapper">
                    <div class="projects-details__img">
                      {image ? (
                        <img
                          src={image}
                          className="w-[100%] mb-12 object-cover object-center"
                          alt={data.title_idn || "Gambar berita"}
                        />
                      ) : (
                        <div class="flex items-center justify-center h-48 mb-12 bg-gray-300 rounded-sm animate-pulse">
                          <svg
                            class="w-10 h-10 text-gray-200 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 16 20"
                          >
                            <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                          </svg>
                        </div>
                      )}

                      {data?.content_idn && (
                        <div
                          classNameName="text-justify"
                          dangerouslySetInnerHTML={{
                            __html: data?.content_idn,
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                role="status"
                class=" p-4 border border-gray-200 rounded-sm shadow-sm animate-pulse md:p-6 "
              >
                <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded-sm ">
                  <svg
                    class="w-10 h-10 text-gray-200 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 20"
                  >
                    <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                  </svg>
                </div>
                <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full "></div>

                <span class="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </section>
      </MainLayout>
    </>
  );
}
