import React from 'react';
import MainLayout from '../../layout/MainLayout.tsx';
import PageHeader from '../../components/PageHeader.tsx';

export default function Library() {
  return (
   <>
     <MainLayout children={undefined}>

        <PageHeader title="Perpustakaan" breadcrumb={[{ label: "Beranda", link: "/" },  { label: "Informasi", link: "/" }, { label: "Perpustakaan", link: "/perpustakaan" }]}></PageHeader>


        <section class="shop-one">
			<div class="container">
				<div class="row">
					<div class="col-lg-3">
						<div class="shop-one__sidebar">
							<div class="shop-one__sidebar__item shop-one__sidebar__search">
								<form action="#">
									<input type="text" placeholder="Masukkan kata kunci" />
									<button type="submit"><i class=" icon-search "></i></button>
								</form>

							</div>
							<div class="shop-one__sidebar__item shop-one__sidebar__price">
								<h3 class="shop-one__sidebar__item__title">Tahun Terbit</h3>
								
								<div class="shop-one__sidebar__price-range">
									{/* <div class="range-slider-price" data-range-min="10" data-range-max="200"
										data-limit="200" data-start-min="30" data-start-max="150"
										id="range-slider-price"></div> */}
									<div class="form-group">
										{/* <div class="left">
											<p>$<span id="min-value-rangeslider"></span></p>
											<span>-</span>
											<p>$<span id="max-value-rangeslider"></span></p>
										</div> */}
										<div class="right">
											<button class="thm-btn">
												Filter
											</button>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
					<div class="col-lg-9">
						<div class="row">
							<div class="col-lg-12 shop-one__sorter">
								<p class="shop-one__product-count">Menampilkan 0 hasil</p>
								<select class="shop-one__product-sorter">
									<option value="">Urutkan berdasarkan popularitas</option>
									<option value="">Urutkan berdasarkan terbaru</option>
									<option value="">Urutkan berdasarkan terlama</option>
								</select>
							</div>
						</div>
						<div class="row">
						
							{/* <div class="col-md-6 col-lg-4">
								<div class="shop-one__item">
									<div class="shop-one__image">
										<img src="assets/images/update-14-09-2021/shop/shop-1-8.png" alt="" />
										<a class="shop-one__cart" href="cart.html"><i
												class=" icon-shopping-cart"></i></a>
									</div>
									<div class="shop-one__content text-center">
										<h3 class="shop-one__title"><a href="product-details.html">Carrots</a>
										</h3>
										<p class="shop-one__price">$8.00</p>
										<div class="shop-one__rating">
											<i class="fa fa-star"></i>
											<i class="fa fa-star"></i>
											<i class="fa fa-star"></i>
											<i class="fa fa-star"></i>
											<i class="fa fa-star"></i>
										</div>
									</div>
								</div>
							</div> */}
						
						</div>
					</div>
				</div>
			</div>
		</section>
       



        </MainLayout>
   </>
  );
}