import React, { useEffect, useState } from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";
import { useParams } from "react-router-dom";
import STORE_URL from "../utils/EncodeUrl.ts";
import axios from "axios";

export default function InternalAuditQuality() {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCriteriaData = async () => {
      try {
        setLoading(true);

        const url = STORE_URL(`path=web/content/find/kriteria-${id}`);

        axios
          .get(url)
          .then((response) => {
            setNewsItem(response.data.data);
          })
          .catch((error) => {
            console.error("Terjadi error:", error);
          });
      } catch (err: any) {
        setError("Gagal mengambil data penelitian");
      } finally {
        setLoading(false);
      }
    };

    fetchCriteriaData();
  }, [id]);

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title={newsItem?.title_idn}
          breadcrumb={[
            { label: "Beranda", link: "/" },
            {
              label: "Kriteria Data Akreditasi",
              link: "/kriteria-data-akreditasi",
            },
            {
              label: newsItem?.title_idn,
              link: "/content/kriteria/" + id,
            },
          ]}
        ></PageHeader>

        <section className="news-details">
          <div className="container">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : newsItem ? (
              <div className="row">
                <div className="col-xl-12 col-lg-11">
                  <div className="news-details__left">
                    <div className="blog-one__single">
                      <div className="blog-one__single-content blog-details">
                        <h2>{newsItem.title_idn}</h2>

                        {newsItem?.content_en && (
                          <div
                            classNameName="text-justify"
                            dangerouslySetInnerHTML={{
                              __html: newsItem?.content_en,
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                role="status"
                class=" p-4 border border-gray-200 rounded-sm shadow-sm animate-pulse md:p-6 "
              >
                <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded-sm ">
                  <svg
                    class="w-10 h-10 text-gray-200 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 20"
                  >
                    <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                  </svg>
                </div>
                <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full "></div>

                <span class="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </section>
      </MainLayout>
    </>
  );
}
