import React from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';

export default function Library() {
  return (
   <>
     <MainLayout children={undefined}>

        <PageHeader title="Perpustakaan" breadcrumb={[{ label: "Beranda", link: "/" }, { label: "Perpustakaan", link: "/perpustakaan" }]}></PageHeader>


        <section class="projects-details">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="projects-details__wrapper">
                            <div class="projects-details__img">
                                <img className="h-56 sm:h-64 xl:h-80 2xl:h-96 object-cover object-center" src="/assets/images/resources/perpustakaan.jpeg" style={{ width: "100%" } } alt="" />
                            </div>


                            <div className="projects-details__text-box1">
                                <p className="mt-4 text-justify">Perpustakaan Farmasi Universitas Mulawarman terletak di Gedung Farmasi-C lantai 1. Perpustakaan Farmasi memiliki koleksi buku-buku kefarmasian dalam bahasa indonesia maupun bahasa inggris yang meliputi beberapa bidang keilmuan seperti kimia medisinal, obat tradisional, bahan alam, farmasi klinik dan komunitas, formulasi, dan masih banyak lagi. Unit Perpustakaan juga mengkoordinir pengumpulan karya tulis mahasiswa seperti skripsi dan laporan tugas akhir praktik kerja. Perpustakaan ini dilengkapi dengan fasilitas pendukung seperti pendingin ruangan, komputer, printer, meja, kursi serta internet yang terkoneksi langsung dengan Wi-Fi Farmasi Unmul. Jam pelayanan perpustakaan yaitu setiap hari Senin hingga Jumat pukul 08.00-16.00.</p>
                              </div>

                     
                     
                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
       



        </MainLayout>
   </>
  );
}