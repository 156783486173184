import React from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";
import { useParams } from "react-router-dom";

function formatSlug(slug) {
  const parts = slug.split("-");

  const lastPart = parts.pop().replace("-", "/");
  const formattedParts = parts.map(
    (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return [...formattedParts, lastPart].join(" ");
}

export default function AcademicCalendar() {
  const { slug } = useParams();

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Kalender Akademik"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: "Kalender Akademik", link: "/kalender-akademik" },
            { label: formatSlug(slug), link: `/kalender-akademik/${slug}` },
          ]}
        ></PageHeader>

        <section class="projects-details">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="projects-details__wrapper">
                  <div class="projects-details__img">
                    <img
                      src="/assets/images/resources/kalender-akademik-2023-2024.jpg"
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}
