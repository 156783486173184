import React from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';

export default function Value() {
  return (
   <>
     <MainLayout children={undefined}>

        <PageHeader title="Visi dan Misi Kami" breadcrumb={[{ label: "Beranda", link: "/" }, { label: "Visi dan Misi", link: "/visi-misi" }]}></PageHeader>

        <section class="about-three" style={{ backgroundImage: "none" }}>
            <div class="about-three__shape"></div>
            <div class="container">
                <div class="row">
                    
                    <div class="col-xl-6 col-lg-7">
                        <div class="about-three__content-box">
                            <div class="sec-title">
                               
                               
                                <h2  style={{ marginTop: "2rem" }} class="sec-title__title">Visi</h2>
                            </div>
                            <div class="about-three__content-box-inner">
                                <h2>Fakultas yang Berdaya Saing Global, Mandiri, dan Inovatif yang memiliki Keunggulan di Bidang Ilmu Farmasi yang Bermanfaat dan Berkelanjutan</h2>
                                <p>Visi Fakultas Farmasi Universitas Mulawarman adalah menjadi fakultas yang berdaya saing global, mandiri, dan inovatif dengan keunggulan di bidang ilmu farmasi. Visi ini mencerminkan komitmen fakultas untuk menghasilkan kontribusi yang bermanfaat dan berkelanjutan melalui pendidikan, penelitian, dan pengabdian kepada masyarakat. Dengan pendekatan yang progresif, fakultas berupaya menghadirkan solusi inovatif untuk tantangan kesehatan, mendukung perkembangan ilmu farmasi, serta memberikan dampak positif secara lokal maupun global.</p>

                               
                            </div>
                            
                        </div>
                    </div>
                  
                    <div class="col-xl-6 col-lg-5">
                        <div class="about-three__img-box">
                           
                            <div class="about-three__img-box-img">
                                <div class="about-three__img-box-img-inner">
                                    <img src="/assets/images/resources/visi-temp.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </section>
       
        <section class="about-three" style={{ backgroundImage: "none" }}>
            <div class="about-three__shape"></div>
            <div class="container">
                <div class="row">

                    <div class="col-xl-6 col-lg-5">
                        <div class="about-three__img-box">
                           
                            <div class="about-three__img-box-img">
                                <div class="about-three__img-box-img-inner">
                                    <img src="/assets/images/resources/misi-temp.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-xl-5 col-lg-6" >
                      
                        <div class="about-three__content-box">
                            <div class="sec-title">
                                
                               
                                <h2 style={{ marginTop: "2rem" }} class="sec-title__title">Misi</h2>
                            </div>
                            <div class="about-three__content-box-inner" style={{ marginLeft: "1rem" }} >
                                    <p>
                                        1. Mengoptimalkan mutu pendidikan sesuai dengan perkembangan ilmu kefarmasian terkini di tingkat nasional dan global.
                                    </p>
                                    <p>
                                        2. Menyelenggarakan kegiatan tridharma yang adaptif, komprehensif dan bermanfaat berfokus pada Pola Ilmiah Pokok Hutan Tropis Lembab (tropical rain forest) bagi kesehatan dan kesejahteraan masyarakat.
                                    </p>
                                    <p>
                                        3. Menyelenggarakan tata pamong yang otonom akuntabel dan transparan yang menjamin peningkatan kualitas berkelanjutan.
                                    </p>

                               
                            </div>
                            
                        </div>
                    </div>
   
                </div>
            </div>
        </section>

        <section class="about-three" style={{ backgroundImage: "none" }}>
            <div class="about-three__shape"></div>
            <div class="container">
                <div class="row">
                    
                    <div class="col-xl-6 col-lg-7">
                        <div class="about-three__content-box">
                            <div class="sec-title">
                                <h2 style={{ marginTop: "2rem" }} class="sec-title__title">Tujuan</h2>
                            </div>
                            <div class="about-three__content-box-inner">
                              
                                <p>
                                    1. Meningkatkan mutu pendidikan sesuai dengan perkembangan ilmu kefarmasian terkini di tingkat nasional dan global
                                </p>
                                <p>
                                    2.  Menghasilkan lulusan yang adaptif dan luaran tridharma yang berfokus pada Pola Ilmiah Pokok Hutan Tropis Lembab (tropical rain forest) yang komprehensif dan bermanfaat bagi kesehatan dan kesejahteraan masyarakat.
                                </p>
                                <p>
                                    3. Mewujudkan tata pamong yang otonom akuntabel dan transparan yang menjamin peningkatan kualitas berkelanjutan.
                                </p>
                               
                            </div>
                            
                        </div>
                    </div>
                  
                    <div class="col-xl-6 col-lg-5">
                        <div class="about-three__img-box">
                           
                            <div class="about-three__img-box-img">
                                <div class="about-three__img-box-img-inner">
                                    <img src="/assets/images/resources/tujuan-temp.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>
       


        </MainLayout>
   </>
  );
}