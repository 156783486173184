import React from 'react';
import MainLayout from '../../layout/MainLayout.tsx';
import PageHeader from '../../components/PageHeader.tsx';
import ServiceForm from './components/ServiceForm.tsx';

export default function SholarshipStep() {
	return (
		<>
			<MainLayout children={undefined}>

				<PageHeader title="SKL dan Sertifikat UKOM D3 dan S1 Farmasi Umum" breadcrumb={[{ label: "Beranda", link: "/" }, { label: "layanan Mahasiswa", link: "/content/layanan-mahasiswa" }, { label: "SKL dan Sertifikat UKOM D3 dan S1 Farmasi Umum", link: "/content/skl-dan-sertifikat-ukom-d3-dan-s1-farmasi-umum" }]}></PageHeader>


				<section class="projects-details">
					<div class="container">
						<div class="row">
							<h2 style={{ textAlign: "center", marginBottom: "2rem" }}>Alur Pembuatan SKL dan Sertifikat S1 Farmasi Umum</h2>
							<div class="col-xl-12">
								<div class="projects-details__wrapper">
									<div class="projects-details__img">
										<img src="/assets/images/resources/skl-farmasi-umum.png" style={{ width: "100%" }} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>



				<ServiceForm type="pembuatan-skl-sertifikat-s1-farmasi-umum"></ServiceForm>
			</MainLayout>
		</>
	);
}
