import React from 'react'
import { Link } from 'react-router-dom';

interface PageHeaderProps {
  breadcrumb: { label: string; link: string }[]; 
  title: string; 
}

export default function PageHeader({ breadcrumb, title }: PageHeaderProps) {
  return (
    <section class="page-header clearfix"
            style={{ backgroundImage: "url(/assets/images/resources/background-kegiatan.jpg)", width: "100%",}}>
            <div class="container">
                <div class="page-header__inner text-center clearfix">
                    <ul class="thm-breadcrumb">
                    {breadcrumb.map((item, index) => (
                      <li key={index}>
                        {item.link ? (
                          <Link to={item.link}>{item.label}</Link>
                        ) : (
                          item.label
                        )}
                      </li>
                    ))}
                    </ul>
                    <h2>{title}</h2>
                </div>
            </div>
        </section>
  )
}
