import React, { useState, useEffect } from 'react';
import MainLayout from '../../layout/MainLayout.tsx';
import PageHeader from '../../components/PageHeader.tsx';

export default function AvailableInfo() {
 

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Informasi Tersedia Setiap Saat"
          breadcrumb={[
            { label: 'Beranda', link: '/' },
            { label: 'Informasi Tersedia Setiap Saat', link: '/ppid/informasi-terjadi-setiap-saat' },
          ]}
        ></PageHeader>

  
      </MainLayout>
    </>
  );
}
