import React from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";
import { Carousel } from "flowbite-react";

export default function InternalAuditQuality() {
  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title=" Data Akreditasi"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            {
              label: "Data Akreditasi",
              link: "/akreditasi",
            },
          ]}
        ></PageHeader>

        <section class="projects-details">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="projects-details__wrapper">
                  <div class="projects-details__img">
                    <div className="h-[70vh]">
                      <Carousel>
                        <img
                          className="object-contain object-center h-[100%]"
                          src=" /assets/images/resources/acreds/akr-d3-farmasi.jpg"
                          alt="..."
                        />

                        <img
                          className="object-contain object-center h-[100%] rotate-90"
                          src=" /assets/images/resources/acreds/akr-profesi-apoteker.jpg"
                          alt="..."
                        />

                        <img
                          className="object-contain object-center h-[100%]"
                          src=" /assets/images/resources/acreds/akr-s1-farmasi.jpg"
                          alt="..."
                        />

                        <img
                          className="object-contain object-center h-[100%]"
                          src=" /assets/images/resources/acreds/akr-s1-new.jpg"
                          alt="..."
                        />
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}
