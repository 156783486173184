import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/MainLayout.tsx";
import PageHeader from "../../components/PageHeader.tsx";

import data from "../../data/labs.json";
import { Carousel, List } from "flowbite-react";

const facilities = [
  "1. Thermal hyperalgesia allodynia Ugo Basile.jpg",
  "2. Metabolic cage for mouse Ugo Basile.jpg",
  "3. Plethysmometer Ugo Basile.jpg",
  "4. Rotarod apparatus Orchid Scientific.jpg",
  "4.1 Rotarod apparatus Orchid Scientific.jpg",
  "5. Blood pressure meter for rodent Panlab.jpg",
  "5.1 Blood Pressure meter for rodent PanLab.jpg",
  "6. 4400 Four chamber Isolated Organ Bath Ugo Basile.jpg",
  "6.1 400 Four chamber Isolated Organ Bath Ugo Basile.jpg",
  "7. UHPLC Duo Thermo Scientific.jpg",
  "7.1 UHPLC Duo Thermo Scientific.jpg",
  "8. f570n CryoCube Eppendorf.jpg",
  "9. Bioconcept incubator.jpg",
  "10. HI2211 pH ORP meter Hanna Instruments.jpg",
  "11. Laminar air flow Nuaire NU 126 400E.jpg",
  "12. Centrifuge 5418 Eppendorf.jpg",
  "13. McFarland densitometer Biosan.jpg",
  "14. Lemari bahan.jpg",
  "15. Visible Microplate Reader HALO MPR-96.jpg",
  "16. Spektrofotometer UV Vis Genesys 10S UV-VIS.jpg",
  "17. Colony counter mikroba.jpg",
  "18. Timbangan miligram Precisa XB 220A.jpg",
  "19. Showcase penyimpanan medium mikroba.jpg",
  "20. Kalstein water purification system Model YR54.jpg",
  "22. Manual microtom.jpg",
  "23. Adrona Water Purification System.jpg",
  "24. ProxiMate Buchi.jpg",
  "25. Autoclave BioBase pressure sterilizer.jpg",
  "26. Autoclave GEA.jpg",
  "27. Autoclave.jpg",
  "28. Inkubator bakteri BioConcept.jpg",
  "29. Freezer -20 Thermo Scientific Heratherm .jpg",
  "30. Hot n cold sentrifuge Hermle Z 326 K.jpg",
  "31. Thermo Scientific freezer -20.jpg",
  "32. rt PCR DNA RNA UV Cleaner box UVC_T-MAR.jpg",
  "33. Sentrifuge Hermle Z207 A.jpg",
  "34. rt PCR alat pendukung Bruker Alpha II.jpg",
  "34.1 rt PCR alat pendukung Bruker Alpha II.jpg",
  "35. Rotor Gene Q Qiagen.jpg",
  "35. rt PCR .jpg",
  "36. IKA G-L.jpg",
  "37. Thermo Shaker TS-100C Biosan.jpg",
  "38. Dryblotting system set.jpg",
  "39. rt PCR TissueLyser LT Qiagen.jpg",
  "40. NanoPhotometer N50.jpg",
  "41. Sentrifuge Hermle Z206A.jpg",
  "42. kegiatan pelatihan pletismometer digital.jpg",
  "43. fasilitas breeding rodent ruang hewan.jpg",
  "44. lab farmakologi lama.jpg",
  "45. mikrosentrifuge.jpg",
  "46. organ bath dan blood pressure test.jpg",
  "47. UHPLC.jpg",
];

interface LaboratoriumProps {
  id: string;
  title: string;
  url: string;
}

export default function Laboratorium({ id, title, url }: LaboratoriumProps) {
  const [currentData, setCurrentData] = useState<{
    title: string;
    description: string;
  } | null>(null);

  useEffect(() => {
    const matchedData = data.find((item) => item.id === id);

    console.log("isi id", id);

    if (!matchedData) {
      console.error(`Data tidak ditemukan untuk ID: ${id}`);
    }

    setCurrentData(matchedData || null);
  }, [id]);

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title={title} // Menggunakan props title
          breadcrumb={[
            { label: "Beranda", link: "/" },
            { label: title, link: url }, // Menggunakan props url untuk breadcrumb
          ]}
        ></PageHeader>

        <section class="projects-details">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="projects-details__wrapper">
                  <div class="projects-details__img">
                    <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
                      <Carousel>
                        {currentData?.images ? (
                          currentData.images.map((image: any, index: any) => (
                            <img
                              className="object-cover object-center"
                              src={image}
                              alt={index}
                            />
                          ))
                        ) : (
                          <img
                            className="object-cover object-center"
                            src=" https://flowbite.com/docs/images/carousel/carousel-3.svg"
                            alt="..."
                          />
                        )}
                      </Carousel>
                    </div>
                  </div>

                  {currentData ? (
                    <div className="projects-details__text-box1">
                      <h2>{currentData.title}</h2>
                      <p className="mt-4 text-justify">
                        {currentData.description}
                      </p>
                    </div>
                  ) : (
                    <p>Data laboratorium tidak ditemukan.</p>
                  )}
                </div>
              </div>
            </div>

            {currentData?.facilities && (
              <>
                <h3 className="bold text-2xl mt-8 mb-4">Fasilitas</h3>
                <p className="capitalize">
                  {currentData.facilities.map((item, index) => (
                    <> {item},</>
                  ))}
                </p>
              </>
            )}

            {id === "3" && (
              <div className="row my-12">
                {facilities.map((item) => (
                  <div class="col-xl-3 col-lg-3 ">
                    <div class="features-one__single">
                      <div class="features-one__single-img">
                        <img
                          src={
                            "/assets/images/resources/labs/farmakologi/facilities/" +
                            item
                          }
                          className="h-[20rem] "
                          alt={item}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      </MainLayout>
    </>
  );
}
