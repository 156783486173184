import React, { useState, useEffect } from 'react';
import MainLayout from './../../layout/MainLayout.tsx';
import PageHeader from './../../components/PageHeader.tsx';

export default function Agenda() {
 

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Agenda"
          breadcrumb={[
            { label: 'Beranda', link: '/' },
            { label: 'Agenda', link: '/agenda' },
          ]}
        ></PageHeader>


        <section class="blog-one blog-one--news">
            <div class="blog-one__bg wow slideInDown" data-wow-delay="100ms" data-wow-duration="2500ms"></div>
            <div class="container">
                <div class="row">
                    
                    <div class="col-xl-4 col-lg-4  wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div class="blog-one__single">
                            <div class="blog-one__single-img">
                                <img src="assets/images/blog/blog-v1-img1.jpg" alt="" />
                                <div class="date-box">
                                    <span>30 July, 2021</span>
                                </div>
                                <div class="overlay-icon">
                                    <a href="news-details.html"><span class="icon-plus"></span></a>
                                </div>
                            </div>

                            <div class="blog-one__single-content">
                                <ul class="meta-info">
                                    <li><a href="/"><i class="far fa-user-circle"></i>Jessica</a></li>
                                    <li><a href="/"><i class="far fa-comments"></i>2 Comments</a></li>
                                </ul>
                                <h2><a href="news-details.html">Why Eco and Walking or Agriculture for the
                                        Environment?</a></h2>
                            </div>
                        </div>
                    </div>
                  
                    <div class="col-xl-4 col-lg-4  wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div class="blog-one__single">
                            <div class="blog-one__single-img">
                                <img src="assets/images/blog/blog-v1-img2.jpg" alt="" />
                                <div class="date-box">
                                    <span>30 July, 2021</span>
                                </div>
                                <div class="overlay-icon">
                                    <a href="news-details.html"><span class="icon-plus"></span></a>
                                </div>
                            </div>

                            <div class="blog-one__single-content">
                                <ul class="meta-info">
                                    <li><a href="/"><i class="far fa-user-circle"></i>Jessica</a></li>
                                    <li><a href="/"><i class="far fa-comments"></i>2 Comments</a></li>
                                </ul>
                                <h2><a href="news-details.html">Leverage agile frameworks to provide a robust
                                        synopsis</a></h2>
                            </div>
                        </div>
                    </div>
                  
                    <div class="col-xl-4 col-lg-4  wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div class="blog-one__single">
                            <div class="blog-one__single-img">
                                <img src="assets/images/blog/blog-v1-img3.jpg" alt="" />
                                <div class="date-box">
                                    <span>30 July, 2021</span>
                                </div>
                                <div class="overlay-icon">
                                    <a href="news-details.html"><span class="icon-plus"></span></a>
                                </div>
                            </div>

                            <div class="blog-one__single-content">
                                <ul class="meta-info">
                                    <li><a href="/"><i class="far fa-user-circle"></i>Jessica</a></li>
                                    <li><a href="/"><i class="far fa-comments"></i>2 Comments</a></li>
                                </ul>
                                <h2><a href="news-details.html">Bring to the table win-win survival strategies to
                                        ensure</a></h2>
                            </div>
                        </div>
                    </div>
                   
                    <div class="col-xl-4 col-lg-4  wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div class="blog-one__single">
                            <div class="blog-one__single-img">
                                <img src="assets/images/blog/blog-v1-img4.jpg" alt="" />
                                <div class="date-box">
                                    <span>30 July, 2021</span>
                                </div>
                                <div class="overlay-icon">
                                    <a href="news-details.html"><span class="icon-plus"></span></a>
                                </div>
                            </div>

                            <div class="blog-one__single-content">
                                <ul class="meta-info">
                                    <li><a href="/"><i class="far fa-user-circle"></i>Jessica</a></li>
                                    <li><a href="/"><i class="far fa-comments"></i>2 Comments</a></li>
                                </ul>
                                <h2><a href="news-details.html">Capitalize on low hanging fruit to identify a
                                        ballpark</a></h2>
                            </div>
                        </div>
                    </div>
                  
                    <div class="col-xl-4 col-lg-4  wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div class="blog-one__single">
                            <div class="blog-one__single-img">
                                <img src="assets/images/blog/blog-v1-img5.jpg" alt="" />
                                <div class="date-box">
                                    <span>30 July, 2021</span>
                                </div>
                                <div class="overlay-icon">
                                    <a href="news-details.html"><span class="icon-plus"></span></a>
                                </div>
                            </div>

                            <div class="blog-one__single-content">
                                <ul class="meta-info">
                                    <li><a href="/"><i class="far fa-user-circle"></i>Jessica</a></li>
                                    <li><a href="/"><i class="far fa-comments"></i>2 Comments</a></li>
                                </ul>
                                <h2><a href="news-details.html">Taking seamless key performance indicators offline
                                        to</a></h2>
                            </div>
                        </div>
                    </div>
                   
                    <div class="col-xl-4 col-lg-4  wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div class="blog-one__single">
                            <div class="blog-one__single-img">
                                <img src="assets/images/blog/blog-v1-img6.jpg" alt="" />
                                <div class="date-box">
                                    <span>30 July, 2021</span>
                                </div>
                                <div class="overlay-icon">
                                    <a href="news-details.html"><span class="icon-plus"></span></a>
                                </div>
                            </div>

                            <div class="blog-one__single-content">
                                <ul class="meta-info">
                                    <li><a href="/"><i class="far fa-user-circle"></i>Jessica</a></li>
                                    <li><a href="/"><i class="far fa-comments"></i>2 Comments</a></li>
                                </ul>
                                <h2><a href="news-details.html">Override the digital divide with additional click
                                        throughs</a></h2>
                            </div>
                        </div>
                    </div>
                  
                </div>
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <div class="news-sidebar__btn">
                            <a href="/" class="thm-btn">load more post</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

  
      </MainLayout>
    </>
  );
}
