import React from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';

export default function Mosque() {
  return (
   <>
     <MainLayout children={undefined}>

        <PageHeader title="Mushola" breadcrumb={[{ label: "Beranda", link: "/" }, { label: "Mushola", link: "/mushola" }]}></PageHeader>


        <section class="projects-details">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="projects-details__wrapper">
                            <div class="projects-details__img">
                                <img className="h-56 sm:h-64 xl:h-80 2xl:h-96 object-cover object-center" src="/assets/images/resources/mushola.jpeg" style={{ width: "100%" } } alt="" />
                            </div>

                            
                            <div className="projects-details__text-box1">
                                <p className="mt-4 text-justify">Mushola Farmasi Universitas Mulawarman terletak di Gedung Kuliah Farmasi lantai 2 dan di Gedung Farmasi-C lantai 1. Mushola ini dilengkapi dengan toilet, tempat wudhu, sejadah, mukena, speaker, dan pendingin ruangan. Mushola ini dapat digunakan oleh dosen, tenaga kependidikan, dan mahasiswa untuk beribadah.
                                </p>
                              </div> 

                     
                     
                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
       



        </MainLayout>
   </>
  );
}