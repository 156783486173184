import React, { useState, useEffect } from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';
import axios from 'axios';
interface ResearchData {
  id: number;
  title_en: string;
  title_idn: string;
  slug: string;
  published_date: string;
  authors: { id: number; name: string }[];
}

const apiKey = process.env.REACT_APP_FF_API_KEY;
const token = process.env.REACT_APP_FF_API_TOKEN;

export default function Research() {

  const [researchData, setResearchData] = useState<ResearchData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);



  useEffect(() => {
    const fetchResearchData = async () => {
      try {
        setLoading(true);

        // Test
        const response = await axios.get(
          'https://osm.unmul.ac.id/web/research?page=1&limit=100&type=2', {
          headers: {
            'apikey': apiKey,
            'Authorization': `Bearer Testt ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        });


        setResearchData(response.data.data);
      } catch (err: any) {
        setError('Gagal mengambil data penelitian');
      } finally {
        setLoading(false);
      }
    };

    fetchResearchData();
  }, []);

  useEffect(() => {

    if (!error) {
      console.log(researchData);
    }

  }, [error, loading, researchData]);

  console.log(researchData);



  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Penelitian"
          breadcrumb={[
            { label: 'Beranda', link: '/' },
            { label: 'Penelitian', link: '/penelitian' },
          ]}
        ></PageHeader>

        <section className="blog-one blog-one--news">
          <div
            className="blog-one__bg wow slideInDown"
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          ></div>
          <div className="container">

          </div>
        </section>
      </MainLayout>
    </>
  );
}
