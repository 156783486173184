import React from "react";
import MainLayout from "../layout/MainLayout.tsx";
import PageHeader from "../components/PageHeader.tsx";

import surveysData from "../data/surveys.json";
import { Link } from "react-router-dom";

export default function InternalAuditQuality() {
  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Survey Kepuasan Civitas"
          breadcrumb={[
            { label: "Beranda", link: "/" },
            {
              label: "Survey Kepuasan Civitas",
              link: "/survey-kepuasan-civitas",
            },
          ]}
        ></PageHeader>

        <section class="services-one">
          <div class="container">
            <div class="sec-title text-center mb-6">
              <h2 class="sec-title__title">Survey Kepuasan</h2>
              <p className="my-3 max-w-screen-lg mx-auto">
                Survei ini bertujuan untuk menilai tingkat kepuasan Mahasiswa,
                Dosen, Tenaga Kependidikan, dan Mitra terhadap berbagai aspek
                yang berkaitan dengan layanan, fasilitas, serta mutu pendidikan
                di fakultas farmasi universitas mulawarman.{" "}
              </p>
            </div>
            <div class="row">
              {surveysData.map((survey, index) => (
                <div
                  class="col-xl-3 col-lg-6 wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1000ms"
                >
                  <div class="services-one__single">
                    <div class="services-one__single-content text-center">
                      <h3>
                        <a href={survey.link}>{survey.title}</a>
                      </h3>
                      <p>{survey.subtitle}</p>
                      <a href={survey.link} class="read-more-btn">
                        <span class="icon-right-arrow-2"></span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div class="sec-title text-center mb-6 mt-24">
              <h2 class="sec-title__title">Hasil Survey Kepuasan</h2>
              <p className="my-3 max-w-screen-lg mx-auto">
                Hasil survei ini diperoleh dari responden yang berpartisipasi
                dalam penilaian tingkat kepuasan terhadap layanan, fasilitas,
                dan mutu pendidikan.
              </p>

              <div class="row">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                  <div class="col-xl-4 col-lg-6 ">
                    <div class="features-one__single">
                      <div class="features-one__single-img">
                        <img
                          src={
                            "/assets/images/resources/survey-results/survey-" +
                            item +
                            ".png"
                          }
                          className=" "
                          alt={item}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}
