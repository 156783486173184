import React, { } from 'react';
import MainLayout from '../layout/MainLayout.tsx';
import PageHeader from '../components/PageHeader.tsx';
import { Link } from 'react-router-dom';

export default function StudyCost() {
 

  return (
    <>
      <MainLayout children={undefined}>
        <PageHeader
          title="Biaya Pendidikan"
          breadcrumb={[
            { label: 'Beranda', link: '/' },
            { label: 'Biaya Pendidikan"', link: '/biaya-pendidikan' },
          ]}
        ></PageHeader>
   

          <section class="cart-table">
                <div class="container" style={{ marginBottom: "4rem" }}>

                <div class="projects-details__text-box1 mt-[-2rem]">
                    <h2 class="text-center">Uang Kuliah Tunggal (UKT)</h2>
                    <p class="text1 text-justify">Uang Kuliah Tunggal (UKT) adalah sistem pembayaran pendidikan tinggi di Indonesia yang menggabungkan seluruh komponen biaya pendidikan ke dalam satu pembayaran setiap semester. Sistem ini bertujuan untuk menciptakan pemerataan akses pendidikan dengan menentukan besaran UKT berdasarkan kemampuan ekonomi keluarga mahasiswa. Dengan UKT, mahasiswa tidak perlu lagi membayar biaya tambahan untuk praktikum atau kegiatan akademik lainnya.</p>
                </div>

               

                </div>
		      </section>



  
      </MainLayout>
    </>
  );
}
